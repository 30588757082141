import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ABOUT, CONTACT, modalShown } from "./redux/modalSlice";

import { FaCompressArrowsAlt, FaExpandArrowsAlt } from "react-icons/fa";
import MenuOverlay from "./MenuOverlay";

// this component is for the black footer bar at the bottom
// of the page

const Footer = () => {
    const dispatch = useDispatch();
    const [showMenu, setShowMenu] = useState(false);
    // const [showResourceMenu, setShowResourceMenu] = useState(false);
    // const [showAccessibilityMenu, setShowAccessibilityMenu] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    // Watch for fullscreenchange
    useEffect(() => {
        const onFullscreenChange = () => {
            setIsFullscreen(Boolean(document.fullscreenElement));
        };

        document.addEventListener("fullscreenchange", onFullscreenChange);

        return () =>
            document.removeEventListener(
                "fullscreenchange",
                onFullscreenChange
            );
    }, []);

    const toggleFullscreen = () => {
        const element = document.getElementsByTagName("html")[0];

        if (!document.fullscreenElement) {
            element.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    return (
        <div className="footer">
            <span className="spacer">&nbsp;</span>
            <span className="footer-menu">
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowMenu(true);
                    }}
                >
                    Resources
                </a>
                {showMenu && (
                    <>
                        <MenuOverlay clickHandler={() => setShowMenu(false)} />
                        <div className="footer-popup-menu resource-menu">
                            <a
                                href="https://www.texasflood.org/flood-basics/index.html"
                                target="_blank"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Preparing Before a Flood
                            </a>
                            <a
                                href="https://www.texasflood.org/emergency-prep/index.html"
                                target="_blank"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Being Safe During a Flood
                            </a>
                            <a
                                href="https://www.texasflood.org/recovery/index.html"
                                target="_blank"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Recovering After a Flood
                            </a>
                        </div>
                    </>
                )}
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        // setShowModal(true);
                        dispatch(modalShown(ABOUT));
                    }}
                >
                    About
                </a>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        // setShowModal(true);
                        dispatch(modalShown(CONTACT));
                    }}
                >
                    Contact
                </a>
                {/* <a
                    href="mailto:tnris_is_support@twdb.texas.gov?subject=TexasFlood.org Version: 3.1.1"
                    target="_top"
                >
                    Contact
                </a> */}
                {/* <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowAccessibilityMenu(true);
                    }}
                >
                    Accessibilty
                </a>
                {showAccessibilityMenu && (
                    <>
                        <MenuOverlay
                            clickHandler={() => setShowAccessibilityMenu(false)}
                        />
                        <div className="footer-popup-menu accessibility-menu">
                            <a
                                href="#/currentFloodConditions"
                                target="_blank"
                                onClick={() => {
                                    setShowAccessibilityMenu(false);
                                }}
                            >
                                Current Flood Conditions
                            </a>
                            <a
                                href="#"
                                target="_blank"
                                onClick={() => {
                                    setShowAccessibilityMenu(false);
                                }}
                            >
                                Current Lake Conditions
                            </a>
                            <a
                                href="#"
                                target="_blank"
                                onClick={() => {
                                    setShowAccessibilityMenu(false);
                                }}
                            >
                                Texas Weather Alerts by County
                            </a>
                            <a
                                href="#"
                                target="_blank"
                                onClick={() => {
                                    setShowAccessibilityMenu(false);
                                }}
                            >
                                State Parks
                            </a>
                        </div>
                    </>
                )} */}
            </span>
            <span className="full-screen-control">
                <button onClick={toggleFullscreen}>
                    {isFullscreen ? (
                        <FaCompressArrowsAlt aria-label="compress screen" />
                    ) : (
                        <FaExpandArrowsAlt aria-label="expand screen" />
                    )}
                </button>
            </span>
        </div>
    );
};

export default Footer;
