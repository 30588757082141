import { useSelector } from "react-redux";
import { getToasts } from "./redux/toasterSlice";
import Toast from "./Toast";

const ToastContainer = () => {
    const toasts = useSelector(getToasts);

    return (
        <div className="toast-container">
            {toasts.map((toast) => (
                <Toast key={toast.id} toast={toast} />
            ))}
        </div>
    );
};

export default ToastContainer;
