import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { WEATHER_RADAR } from "../layers/constants";
import { getActiveFeatureLayers } from "../redux/mapSlice";
import {
    getCurrentRadarIndex,
    getRadarAnimating,
    getRadarTimestamps,
    toggleRadarAnimation,
} from "../redux/weatherRadarSlice";
import { getSidebarShowing } from "../redux/sidebarSlice";
import { FaPauseCircle, FaPlayCircle } from "react-icons/fa";

// this module controls the radar animation pause/play button
// when the Weather Radar layer is turned on

const RadarAnimationControl = () => {
    const activeFeatureLayers = useSelector(getActiveFeatureLayers);
    const isRadarAnimating = useSelector(getRadarAnimating);
    const currentRadarIndex = useSelector(getCurrentRadarIndex);
    const radarTimestamps = useSelector(getRadarTimestamps);
    const sidebarShowing = useSelector(getSidebarShowing);
    const dispatch = useDispatch();

    if (!(activeFeatureLayers & WEATHER_RADAR) || currentRadarIndex === -1)
        return null;

    const icon = isRadarAnimating ? (
        <FaPauseCircle size="1.5rem" aria-label="pause" />
    ) : (
        <FaPlayCircle size="1.5rem" aria-label="play" />
    );

    const handleClick = () => {
        dispatch(toggleRadarAnimation());
    };

    const ts = radarTimestamps[currentRadarIndex];
    const year = ts.substring(0, 4);
    const monthIndex = parseInt(ts.substring(4, 6)) - 1;
    const day = ts.substring(6, 8);
    const hour = ts.substring(8, 10);
    const minute = ts.substring(10, 12);
    const dateString = new Date(
        Date.UTC(year, monthIndex, day, hour, minute)
    ).toLocaleString();

    return (
        <div
            className={
                "radar-controls" +
                (sidebarShowing ? " with-sidebar" : " without-sidebar")
            }
        >
            <button onClick={handleClick}>{icon}</button> {dateString}
        </div>
    );
};

export default RadarAnimationControl;
