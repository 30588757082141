import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// this redux slice retrieves all flood gage data for local caching, refreshed at intervals
// note that this is for the gages subscribed to by the customer,
// not the gages the customer clicks on.

export const fetchCurrentFloodGageData = createAsyncThunk(
    "floodGage/fetchCurrentFloodGageData",
    async (coords) => {
        const response = await fetch(
            "https://mapserver.tnris.org/?map=/tnris_mapfiles/nws_ahps_gauges_texas.map&SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&TYPENAMES=CurrentStage&outputformat=geojson&SRSNAME=EPSG:4326"
        );
        const result = await response.json();
        return result.features.reduce((accumulator, gage) => {
            return (accumulator = {
                ...accumulator,
                [gage.properties.lid]: {
                    name: gage.properties.name,
                    stage: gage.properties.stage,
                    sigstage: gage.properties.sigstage,
                    wfo: gage.properties.wfo,
                    latitude: gage.properties.latitude,
                    longitude: gage.properties.longitude,
                    timestamp: gage.properties.timestamp,
                },
            });
        }, {});
    }
);

// floodGages looks like
// {
//     lid: {
//         name,
//         stage,
//         sigstage,
//         wfo,
//         latitude,
//         longitude,
//         timestamp,
//     },
//     ...
// }
const initialState = {
    status: "idle",
    error: null,
    floodGages: null,
};

export const getStatus = (state) => state.floodGage.status;
export const getFloodGages = (state) => state.floodGage.floodGages;

export const floodSlice = createSlice({
    name: "floodGage",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchCurrentFloodGageData.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchCurrentFloodGageData.fulfilled, (state, action) => {
                state.floodGages = action.payload;
                state.status = "succeeded";
            })
            .addCase(fetchCurrentFloodGageData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default floodSlice.reducer;
