import { useDispatch, useSelector } from "react-redux";
import {
    SUBSCRIPTIONS_FORM,
    currentFormChanged,
    getCurrentAlerts,
    getEmail,
    getLids,
    getNewCurrentAlerts,
    getNewPredictiveAlerts,
    getPhone,
    getPredictiveAlerts,
    getUsername,
    newAlertsReset,
    newCurrentAlertsChanged,
    newPredictiveAlertsChanged,
} from "../redux/userSlice";
import { logout } from "../redux/userSliceThunks";
import SwitchBox from "./SwitchBox";
import {
    DELETE_ACCOUNT,
    SAVE_ALERT_CHANGES,
    modalShown,
} from "../redux/modalSlice";

// this compoment displays the user account settings
// including username, phone, email and whether the
// user is subscribed to current or predictive alerts
// or both
// this also contains the html to delete an account

const SAVE = "save";
const DELETE = "delete";

const AccountSettings = () => {
    const username = useSelector(getUsername);
    const phone = useSelector(getPhone);
    const email = useSelector(getEmail);
    const current = useSelector(getCurrentAlerts);
    const predictive = useSelector(getPredictiveAlerts);
    const lids = useSelector(getLids);
    const dispatch = useDispatch();

    const newCurrent = useSelector(getNewCurrentAlerts);
    const newPredictive = useSelector(getNewPredictiveAlerts);

    const handleShowModal = (e, modalContent) => {
        e.preventDefault();
        if (modalContent === SAVE) {
            dispatch(modalShown(SAVE_ALERT_CHANGES));
        } else {
            dispatch(modalShown(DELETE_ACCOUNT));
        }
    };

    return (
        <div className="account-settings">
            <h2>Account Settings</h2>
            <p>
                <strong>Username:</strong> {username}
            </p>
            <p>
                <strong>Phone:</strong> {phone}
            </p>
            <p>
                <strong>Email:</strong> {email}
            </p>
            <p>
                <strong>Flood Gage Alert Types:</strong>
            </p>
            <form
                className="save-alerts-form"
                onSubmit={(e) => {
                    handleShowModal(e, SAVE);
                }}
            >
                <div>
                    <small>Current</small>
                    <small>Predictive</small>
                    <SwitchBox
                        name="current"
                        onChange={() => {
                            dispatch(newCurrentAlertsChanged(!newCurrent));
                            // setNewCurrent((prev) => !prev);
                        }}
                        checked={newCurrent}
                    />
                    <SwitchBox
                        name="predictive"
                        onChange={() => {
                            dispatch(
                                newPredictiveAlertsChanged(!newPredictive)
                            );
                            // setNewPredictive((prev) => !prev);
                        }}
                        checked={newPredictive}
                    />
                </div>
                <button
                    type="submit"
                    disabled={
                        current === newCurrent && predictive === newPredictive
                    }
                >
                    SAVE CHANGES
                </button>
            </form>
            {lids.length > 0 && (
                <p>
                    Please unsubscribe from all gages before deleting your
                    account.
                </p>
            )}
            <form
                className="delete-account-form"
                onSubmit={(e) => {
                    handleShowModal(e, DELETE);
                }}
            >
                <button type="submit" disabled={lids.length !== 0}>
                    DELETE ACCOUNT
                </button>
            </form>
            <div className="nav-buttons">
                <button
                    onClick={() => {
                        dispatch(newAlertsReset());
                        dispatch(currentFormChanged(SUBSCRIPTIONS_FORM));
                    }}
                >
                    SUBSCRIPTIONS
                </button>
                <button
                    onClick={() => {
                        dispatch(logout());
                    }}
                >
                    SIGN OUT
                </button>
            </div>
        </div>
    );
};

export default AccountSettings;
