import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchGeocode,
    getGeocoderResults,
    getGeocoderShowing,
    hideGeocoder,
    selectGeocodeResult,
} from "../redux/geocoderSlice";
import { FaTimes } from "react-icons/fa";

// this module controls the Geocoder field that appears in the top right corner
// when the icon on the header bar is clicked

const GeocoderControl = () => {
    const isGeocoderShowing = useSelector(getGeocoderShowing);
    const geocodeResults = useSelector(getGeocoderResults);
    const dispatch = useDispatch();
    const [query, setQuery] = useState("");

    useEffect(() => {
        if (geocodeResults.length < 1) {
            setQuery("");
        }
    }, [geocodeResults]);

    if (!isGeocoderShowing) return null;

    const handleChange = (e) => {
        setQuery(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && query.length > 0) {
            e.preventDefault();
            dispatch(fetchGeocode(query));
        }
    };

    const handleClose = () => {
        setQuery("");
        dispatch(hideGeocoder());
    };

    const handleResponseChosen = (index) => {
        // dispatch(hideGeocoder())
        console.log("chosen", index);
        dispatch(selectGeocodeResult(geocodeResults[index]));
    };

    let menu = null;
    console.log("geocodeResults at this point", geocodeResults);
    if (geocodeResults.length > 1) {
        menu = (
            <ul className="menu">
                {geocodeResults.map((geocode, index) => {
                    console.log("geocode li", geocode);
                    return (
                        <li
                            key={index}
                            onClick={() => {
                                handleResponseChosen(index);
                            }}
                        >
                            {geocode.display_name}
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <div className="geocoder-container">
            <div className="geocoder">
                <input
                    value={query}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Search by City or Street Address"
                />
                <button onClick={handleClose}>
                    <FaTimes aria-label="close" />
                </button>
            </div>
            {menu}
        </div>
    );
};

export default GeocoderControl;
