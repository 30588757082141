import React, { useId } from "react";

const SwitchBox = ({ name, onChange, checked }) => {
    return (
        <label className="switch-box">
            <input
                type="checkbox"
                name={name}
                onChange={onChange}
                checked={checked}
                aria-label={name}
            />
            <span></span>
        </label>
    );
};

export default SwitchBox;
