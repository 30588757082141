import { useSelector } from "react-redux";
import { getPopupData } from "../redux/mapSlice";
import PopupTitle from "./PopupTitle";
import icon from "../images/flood_alert_red.png";

// this component is for the Leaflet popup which appears when you
// click on one of the weather alert layer areas

const titleStyles = {
    fontWeight: "600",
    fontSize: "1.5em",
    margin: "0px 18px 3px 18px",
    verticalAlign: "middle",
};
const weatherAlertsStyles = {
    fontFamily: "monospace",
    fontWeight: "400",
    fontSize: "1em",
    margin: "4px 18px 0px 18px",
    paddingBottom: "20px",
    maxWidth: "600px",
};

const WeatherAlertsPopup = ({ handleClose }) => {
    const popupData = useSelector(getPopupData);

    return (
        // style={{maxHeight: "500px", minWidth: "500px", overflow: "scroll"}}
        <div
            style={{
                maxHeight: "578px",
                height: "100%",
                display: "grid",
                gridTemplateRows: "auto 1fr",
            }}
        >
            <PopupTitle
                icon={icon}
                title="Weather Alert"
                handleClose={handleClose}
            />
            {popupData.alerts && popupData.alerts.length > 0 && (
                <div
                    style={{
                        overflowY: "auto",
                    }}
                >
                    {popupData.alerts.map((alert, index) => (
                        <div key={index}>
                            <h2 style={titleStyles}>{alert.name}</h2>
                            <p style={weatherAlertsStyles}>{alert.body}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default WeatherAlertsPopup;
