import React from "react";
import Modal from "./Modal";
import { useDispatch } from "react-redux";
import { modalHidden } from "../redux/modalSlice";
import { FaTimes } from "react-icons/fa";

const Contact = () => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(modalHidden());
    };

    return (
        <Modal>
            <div className="contact">
                <div className="title">
                    <button onClick={handleClose}>
                        <FaTimes aria-label="close" />
                    </button>
                </div>
                <div className="body">
                    <div className="contact-content">
                        <p>
                            For application functionality related questions
                            please contact:{" "}
                            <a
                                href="mailto:txgio_app_support@twdb.texas.gov?subject=TexasFlood.org Version: 3.1.1"
                                target="_top"
                            >
                                txgio_app_support@twdb.texas.gov
                            </a>
                            .
                        </p>
                        <p>
                            For data related questions please contact the
                            National Weather Service at{" "}
                            <a href="https://www.weather.gov/contact">
                                https://www.weather.gov/contact
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Contact;
