import { createSlice } from "@reduxjs/toolkit";

// possible values for currentModal in the state
export const DISCLAIMER = "disclaimer";
export const ABOUT = "about";
export const CONTACT = "contact";
export const SAVE_ALERT_CHANGES = "saveAlertChanges";
export const SAVE_SUBSCRIPTION_CHANGES = "saveSubscriptionChanges";
export const DELETE_ACCOUNT = "deleteAccount";
export const CONFIRM_SUBSCRIPTION = "confirmSubscription";

const initialState = {
    currentModal: "",
};

export const getCurrentModal = (state) => state.modal.currentModal;

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        modalShown: (state, action) => {
            state.currentModal = action.payload;
        },
        modalHidden: (state, action) => {
            state.currentModal = "";
        },
    },
});

export const { modalShown, modalHidden } = modalSlice.actions;

export default modalSlice.reducer;
