import { configureStore } from "@reduxjs/toolkit";
import mapReducer from "./mapSlice";
import aerisAlertsReducer from "./aerisAlertsSlice";
import weatherRadarReducer from "./weatherRadarSlice";
import geoJsonReducer from "./geoJsonSlice";
import geocoderReducer from "./geocoderSlice";
import geolocationReducer from "./geolocationSlice";
import userReducer from "./userSlice";
import floodGageReducer from "./floodSlice";
import toasterReducer from "./toasterSlice";
import sidebarReducer from "./sidebarSlice";
import modalReducer from "./modalSlice";

// this module combines all the Redux state slices into one store

export default configureStore({
    reducer: {
        map: mapReducer,
        aerisAlerts: aerisAlertsReducer,
        weatherRadar: weatherRadarReducer,
        geoJson: geoJsonReducer,
        geocoder: geocoderReducer,
        geolocation: geolocationReducer,
        user: userReducer,
        floodGage: floodGageReducer,
        toaster: toasterReducer,
        sidebar: sidebarReducer,
        modal: modalReducer,
    },
});
