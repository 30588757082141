import L from "leaflet";
import { FLOOD_GAGES } from "./constants";
import { showPopup, hidePopup } from "../redux/mapSlice";
import { fetchCurrentFloodGageData } from "../redux/floodSlice";

// This module defines the class for feature layers which have
// a UTF grid (https://blog.mapbox.com/how-interactivity-works-with-utfgrid-3b7d437f9ca9)
// above their tile layer
// The layers that use this class are the Flood Gage layer and the Lake Conditions layer

const getTileUrl = (mapfile) => {
    return `https://mapserver.tnris.org/?map=/tnris_mapfiles/${mapfile}.map&mode=tile&tilemode=gmap&tile={x}+{y}+{z}&layers=all&map.imagetype=png`;
};

const getGridUrl = (mapfile, interactivity, nobuff) => {
    const type = nobuff ? nobuff : mapfile;
    return `https://mapserver.tnris.org/?map=/tnris_mapfiles/${type}.map&mode=tile&tilemode=gmap&tile={x}+{y}+{z}&layers=${interactivity}&map.imagetype=utfgrid`;
};

export default class UtfGridLayer {
    constructor(
        id,
        mapFile,
        interactivity,
        nobuff,
        attribution,
        zIndex,
        refreshTimeMs,
        popupPayloadParameters,
        reduxStore
    ) {
        this.id = id;

        this.tileLayer = L.tileLayer(getTileUrl(mapFile), {
            attribution,
            zIndex,
        });

        this.utfGridLayer = L.utfGrid(
            getGridUrl(mapFile, interactivity, nobuff),
            {
                useJsonP: false,
            }
        );

        this.refreshTimeMs = refreshTimeMs;

        // function for refreshing data if it's locally cached for all clickable points
        // (currently only applicable to flood gage data)
        this.locallyCachedData = null;
        this.popupPayloadParameters = popupPayloadParameters;
        this.reduxStore = reduxStore;
    }

    initialize() {
        this.utfGridLayer.on("click", (e) => {
            L.DomEvent.preventDefault(e);
            L.DomEvent.stopPropagation(e);
            if (!e.data) return;

            console.log("utf grid data", e.data);

            const layerSpecificPayload = this.popupPayloadParameters.reduce(
                (accumulator, param) => {
                    return (accumulator = {
                        ...accumulator,
                        [param]: e.data[param],
                    });
                },
                {}
            );

            const payload = {
                id: this.id,
                ...layerSpecificPayload,
                lat: e.data.latitude || e.latlng.lat,
                lng: e.data.longitude || e.latlng.lng,
                eventTimeStamp: e.originalEvent.timeStamp,
            };
            this.reduxStore.dispatch(showPopup(payload));
        });

        this.loadLocalCache();
    }

    loadLocalCache() {
        if (this.id === FLOOD_GAGES) {
            this.reduxStore.dispatch(fetchCurrentFloodGageData());
        }
    }

    async update() {
        this.loadLocalCache();

        this.tileLayer.redraw();
        this.utfGridLayer.redraw();
    }

    show(map) {
        map.addLayer(this.tileLayer);
        map.addLayer(this.utfGridLayer);
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        this.refreshInterval = setInterval(() => {
            this.update();
        }, this.refreshTimeMs);
    }

    hide(map) {
        map.removeLayer(this.tileLayer);
        map.removeLayer(this.utfGridLayer);

        map.closePopup();
        this.reduxStore.dispatch(hidePopup());
        clearInterval(this.refreshInterval);
    }
}
