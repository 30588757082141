import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentAlerts, getPredictiveAlerts } from "../redux/userSlice";
import { addToast } from "../redux/toasterSlice";

const AlertTypeIndicator = () => {
    const current = useSelector(getCurrentAlerts);
    const predictive = useSelector(getPredictiveAlerts);

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(
            addToast({
                msg: "Edit your account Settings to change the types of alerts you receive.",
                duration: 4000,
            })
        );
    };

    const currentState = current ? "on" : "off";
    const predictiveState = predictive ? "on" : "off";
    return (
        <div className="alert-type-indicator">
            <span
                className={`badge turned-${currentState}`}
                title={`Current alerts are turned ${currentState}`}
                onClick={handleClick}
            >
                C
            </span>
            <span
                className={`badge turned-${predictiveState}`}
                title={`Predictive alerts are turned ${predictiveState}`}
                onClick={handleClick}
            >
                P
            </span>
        </div>
    );
};

export default AlertTypeIndicator;
