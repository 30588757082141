import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FLOOD_GAGES } from "../layers/constants";
import { setCenterAndZoom, showPopup } from "../redux/mapSlice";
import { getFloodGages } from "../redux/floodSlice";
import { addToast } from "../redux/toasterSlice";

// This hook zooms to the gage specified in the URL if it's
// of the form https://map.texasflood.org/#/gage/:lid
// It returns the zoomToGage function so that it can also be
// called directly

const useZoomToGage = () => {
    const floodGages = useSelector(getFloodGages);
    const dispatch = useDispatch();
    const routerParams = useParams();
    const gageAlreadyPoppedUp = useRef(false);

    const zoomToGage = (lid) => {
        console.log("ever here");
        if (!floodGages) {
            // this code should never be reached because
            // you should test to see if floodGages is null
            // before calling this function
            return;
        }
        if (floodGages && !floodGages[lid]) {
            dispatch(
                addToast({
                    msg: `Gage ${lid} could not be located.`,
                })
            );
            return;
        }
        const payload = {
            id: FLOOD_GAGES,
            lid,
            name: floodGages[lid].name,
            stage: floodGages[lid].stage,
            sigstage: floodGages[lid].sigstage,
            wfo: floodGages[lid].wfo,
            lat: floodGages[lid].latitude,
            lng: floodGages[lid].longitude,
            timestamp: floodGages[lid].timestamp,
        };
        const centerAndZoom = {
            lat: floodGages[lid].latitude,
            lng: floodGages[lid].longitude,
            zoom: 10,
        };
        dispatch(setCenterAndZoom(centerAndZoom));
        dispatch(showPopup(payload));
    };

    // invoke zoomToGage once floodGages is populated and if
    // the router parameters contain a param called "lid" and
    // if it hasn't already happened
    useEffect(() => {
        if (floodGages && routerParams.lid && !gageAlreadyPoppedUp.current) {
            gageAlreadyPoppedUp.current = true;
            zoomToGage(routerParams.lid.toUpperCase());
        }
    }, [floodGages, routerParams.lid]);

    return zoomToGage;
};

export default useZoomToGage;
