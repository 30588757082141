import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// this redux slices is concerned with the feature that allows
// uploading custom GeoJSON layers

export const fetchGeoJson = createAsyncThunk(
    "geoJson/fetchGeoJson",
    async ({ url, bgColor }) => {
        const response = await fetch(url);
        const geoJson = await response.json();
        return { geoJson, bgColor };
    }
);

const initialState = {
    status: "idle",
    error: null,
    data: [],
    customGeoJson: null,
};

export const getCustomGeoJson = (state) => state.geoJson.customGeoJson;

const geoJsonSlice = createSlice({
    name: "geoJson",
    initialState,
    reducers: {
        setCustomGeoJSONLayer: (state, action) => {
            state.customGeoJson = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchGeoJson.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchGeoJson.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data.push(action.payload);
            })
            .addCase(fetchGeoJson.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export const { setCustomGeoJSONLayer } = geoJsonSlice.actions;
export default geoJsonSlice.reducer;
