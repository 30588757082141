import L from "leaflet";
import { showPopup, hidePopup } from "../redux/mapSlice";
import { fetchGeoJson } from "../redux/geoJsonSlice";

// This module defines the class for feature layers which are defined
// using GeoJSON (State Parks and WMAs and custom layers)

export default class GeoJsonLayer {
    constructor(id, urls, zIndex, refreshTimeMs, reduxStore) {
        this.id = id;
        this.urls = urls;
        this.zIndex = zIndex;
        this.refreshTimeMs = refreshTimeMs;
        this.reduxStore = reduxStore;
        if (this.urls) {
            this.urls.forEach((item) => {
                this.reduxStore.dispatch(fetchGeoJson(item));
            });
        }
        // State Parks and WMA GeoJSON has to be fetched on intialization
        // this is for retrying in case of error
        this.exponentialBackOff = 1000;
    }

    initialize(map) {
        this.update(map);
    }

    update(map) {
        // if there are URLs (this only happens with State Parks and WMAs)
        if (this.urls) {
            const storeState = this.reduxStore.getState();
            this.geoJsonLayers = [];
            // if the URLs have been fetched successfully (kicked off in the constructor)
            if (storeState.geoJson.status === "succeeded") {
                // reset exponential back-off for when this is refreshed
                this.exponentialBackOff = 1000;
                // iterate through the State Parks and WMA (they use separate GeoJSON)
                storeState.geoJson.data.forEach((data) => {
                    const geoJsonLayer = L.geoJSON(data.geoJson);
                    // iterate through each feature in the geoJson layer
                    geoJsonLayer.eachLayer((feature) => {
                        feature.setStyle({
                            fillColor: data.bgColor,
                            color: data.bgColor,
                        });
                        feature.on("click", (e) => {
                            L.DomEvent.preventDefault(e);
                            L.DomEvent.stopPropagation(e);

                            const payload = {
                                id: this.id,
                                lat: e.latlng.lat,
                                lng: e.latlng.lng,
                                eventTimeStamp: e.originalEvent.timeStamp,
                            };
                            if (e.target.feature.properties.ParkName) {
                                payload.parkName =
                                    e.target.feature.properties.ParkName;
                                payload.type = "park";
                                payload.title = "State Park";
                            } else if (e.target.feature.properties.LoName) {
                                payload.parkName =
                                    e.target.feature.properties.LoName;
                                payload.type = "wma";
                                payload.title = "Wildlife Management Area";
                            }
                            this.reduxStore.dispatch(showPopup(payload));
                        });
                    });
                    this.geoJsonLayers.push(geoJsonLayer);
                });
            } else {
                console.log("retry");
                this.exponentialBackOff *= 2;
                setTimeout(() => {
                    this.update(map);
                }, this.exponentialBackOff);
            }
        }
    }

    show(map) {
        // if there weren't any URLs passed into the constructor (i.e. this is a custom layer)
        if (!this.urls) {
            const storeState = this.reduxStore.getState();
            // if there is a custom layer
            if (storeState.geoJson.customGeoJson) {
                const customLayer = L.geoJSON(
                    storeState.geoJson.customGeoJson,
                    {
                        fillColor: "gray",
                        color: "gray",
                        // pointToLayer: (feature, latlng) => {
                        //   return L.marker(latlng, {icon: customMarker})
                        // }
                    }
                );
                customLayer.eachLayer((layer) => {
                    layer.on("click", (e) => {
                        e.originalEvent.preventDefault();
                        e.originalEvent.stopPropagation();

                        const payload = {
                            id: this.id,
                            lat: e.latlng.lat,
                            lng: e.latlng.lng,
                            customData: e.target.feature.properties,
                            eventTimeStamp: e.originalEvent.timeStamp,
                        };
                        this.reduxStore.dispatch(showPopup(payload));
                    });
                });
                this.geoJsonLayers = [customLayer];
            }
        }

        // it may be the case that the State Parks and WMA layers haven't loaded yet,
        // so keep trying until they have
        if (!this.geoJsonLayers) {
            setTimeout(() => {
                this.show(map);
            }, 1000);
            return;
        }
        this.geoJsonLayers.forEach((layer) => {
            layer.addTo(map);
        });

        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        this.refreshInterval = setInterval(() => {
            this.update();
        }, this.refreshTimeMs);
    }

    hide(map) {
        this.geoJsonLayers.forEach((layer) => {
            map.removeLayer(layer);
        });

        map.closePopup();
        this.reduxStore.dispatch(hidePopup());
        clearInterval(this.refreshInterval);
    }
}
