import React from "react";
import { useSelector } from "react-redux";
import { getPopupData } from "../redux/mapSlice";
import icon from "../images/custom_button.png";
import PopupTitle from "./PopupTitle";

// this component is for the Leaflet popup which appears when
// you click on a custom overlay

const colStyle = {
    border: "1px solid #ddd",
    padding: "8px",
};

const cell = {
    padding: "5px",
};

const boldCell = {
    padding: "5px",
    fontWeight: "bold",
};

const CustomLayerPopup = ({ handleClose }) => {
    const popupData = useSelector(getPopupData);

    return (
        <div>
            <PopupTitle
                icon={icon}
                title="Custom Layer"
                handleClose={handleClose}
            />
            <table className="custom-layer-table">
                <tbody>
                    {Object.entries(popupData.customData).map(
                        (entry, index) => {
                            return (
                                <tr key={index} style={Object.assign(colStyle)}>
                                    <td style={boldCell}>{entry[0]}</td>
                                    <td style={cell}>{entry[1]}</td>
                                </tr>
                            );
                        }
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default CustomLayerPopup;
