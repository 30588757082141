import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getCustomGeoJson } from "../redux/geoJsonSlice";
import MapLayerControl from "./MapLayerControl";
import {
    FLOOD_GAGES,
    LAKE_CONDITIONS,
    WEATHER_ALERTS,
    WEATHER_RADAR,
    STATE_PARKS,
    CUSTOM_OVERLAY,
} from "../layers/constants";
import UserControls from "./UserControls";
import {
    gagesToUnsubscribeReset,
    getAuthenticated,
    newAlertsReset,
} from "../redux/userSlice";
import { FaBell, FaPen, FaRegMap } from "react-icons/fa";
import { getSidebarShowing, sidebarShown } from "../redux/sidebarSlice";
import { useDispatch } from "react-redux";

// this component contains the sidebar including the
// map layers and the various user forms

const Sidebar = () => {
    const sidebarShowing = useSelector(getSidebarShowing);
    const customGeoJson = useSelector(getCustomGeoJson);
    const authenticated = useSelector(getAuthenticated);
    const dispatch = useDispatch();

    const [mapControlsOpen, setMapControlsOpen] = useState(true);
    const [userControlsOpen, setUserControlsOpen] = useState(false);
    const setInitially = useRef(false);

    if (!setInitially.current) {
        setInitially.current = true;
        if (window.location.href.endsWith("/subscriptions")) {
            setMapControlsOpen(false);
            setUserControlsOpen(true);
            dispatch(sidebarShown());
        }
    }

    let layerControls;
    if (mapControlsOpen) {
        layerControls = (
            <>
                <MapLayerControl layer={FLOOD_GAGES} />
                <MapLayerControl layer={LAKE_CONDITIONS} />
                <MapLayerControl layer={WEATHER_ALERTS} />
                <MapLayerControl layer={WEATHER_RADAR} />
                <MapLayerControl layer={STATE_PARKS} />
                {customGeoJson && <MapLayerControl layer={CUSTOM_OVERLAY} />}
            </>
        );
    }

    return (
        <section
            className={
                "sidebar" + (sidebarShowing ? " show-sidebar" : " hide-sidebar")
            }
            aria-label="sidebar"
        >
            <div className="sidebar-content">
                <button
                    type="button"
                    onClick={() => {
                        setMapControlsOpen((prev) => !prev);
                    }}
                    className="section-header"
                    role="button"
                    aria-label="maplayers"
                    tabIndex="0"
                >
                    <span className="icon">
                        <FaRegMap />
                    </span>
                    <span>MAP LAYERS</span>
                </button>
                {layerControls}
                <button
                    type="button"
                    onClick={() => {
                        if (userControlsOpen) {
                            dispatch(newAlertsReset());
                            dispatch(gagesToUnsubscribeReset());
                        }
                        setUserControlsOpen((prev) => !prev);
                    }}
                    className="section-header"
                    role="button"
                    aria-label="usercontrols"
                    tabIndex="0"
                >
                    <span className="icon">
                        {authenticated ? <FaBell /> : <FaPen />}
                    </span>
                    <span>{authenticated ? "MY GAGE ALERTS" : "SIGN IN"}</span>
                </button>
                {userControlsOpen && <UserControls />}
            </div>
        </section>
    );
};

export default Sidebar;
