import React from "react";
import { useSelector } from "react-redux";
import PopupTitle from "./PopupTitle";
import { getPopupData } from "../redux/mapSlice";
import icon from "../images/boat_icon_popup_title.png";

// this component is for the Leaflet popup which appears when
// you click on one of the lakes in the Lake Conditions layer

const LakeConditionsPopup = ({ handleLoaded, handleClose }) => {
    const popupData = useSelector(getPopupData);
    // TODO: refresh contents when data
    const src = `https://waterdatafortexas.org/reservoirs/api/individual/${popupData.lake_url_name}/recent-elevations-chart`;
    const link = `https://waterdatafortexas.org/reservoirs/individual/${popupData.lake_url_name}`;

    return (
        <div>
            <PopupTitle
                icon={icon}
                title="Lake Conditions"
                handleClose={handleClose}
            />
            <a href={link} target="lake-conditions">
                <img
                    src={src}
                    onLoad={handleLoaded}
                    alt="Click here to be take to the web page for this lake"
                />
            </a>
        </div>
    );
};

export default LakeConditionsPopup;
