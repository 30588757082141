// separating these constants out to avoid circular references
export const STREETS = "Streets";
export const SATELLITE = "Satellite";

// These constants are bit masks for a bit flag register found in the map slice of the Redux store
// The register indicates which feature layers, if any, are shown
// When used as object keys, they are converted to strings
export const FLOOD_GAGES = 1;
export const LAKE_CONDITIONS = 2;
export const WEATHER_ALERTS = 4;
export const WEATHER_RADAR = 8;
export const STATE_PARKS = 16;
export const CUSTOM_OVERLAY = 32;
