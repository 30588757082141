import AWS from "aws-sdk";
import keys from "./keys";
import { PublishCommand, SNSClient } from "@aws-sdk/client-sns";

export const sendSNSError = async (err) => {
    AWS.config.update(keys.awsConfig);
    const snsClient = new SNSClient({
        region: AWS.config.region,
        credentials: AWS.config.credentials,
    });
    console.log(err.name + "\n" + err.stack);
    const response = await snsClient.send(
        new PublishCommand({
            Message: err.name + "\n" + err.stack,
            TopicArn: "arn:aws:sns:us-east-1:746466009731:Stevie",
        })
    );
    console.log(response);
};
