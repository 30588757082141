import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { retrieveAccount } from "./redux/userSliceThunks";

import "./App.scss";

import "./vendor";
import AllModals from "./modal/AllModals";
import Header from "./Header";
import Sidebar from "./sidebar/Sidebar";
import RadarAnimationControl from "./controls/RadarAnimationControl";
import GeocoderControl from "./controls/GeocoderControl";
import Map from "./Map";
import ToastContainer from "./ToastContainer";
import Footer from "./Footer";
import { sidebarHidden, sidebarShown } from "./redux/sidebarSlice";
import { setCenterAndZoom } from "./redux/mapSlice";
import { DISCLAIMER, modalShown } from "./redux/modalSlice";

function App() {
    const dispatch = useDispatch();

    const routerParams = useParams();

    const [disclaimerShown, setDisclaimerShown] = useState(false);

    const toggleSidebar = () => {
        window.innerWidth > 1024
            ? dispatch(sidebarShown())
            : dispatch(sidebarHidden());
    };

    useEffect(() => {
        // retrieve any existing account currently logged in
        // from local storage following a page refresh
        dispatch(retrieveAccount());

        // listen for any resize events and show/hide the sidebar
        const resizeEvent = window.addEventListener("resize", toggleSidebar);

        // if coords match the form "@:lat,:lng,:zoomz"
        // update center and zoom in the Redux store
        let coords = routerParams.coords;
        if (coords && coords.match(/^@\-?\d+\.\d+,\-?\d+\.\d+,\d+z$/)) {
            coords = coords
                .replace(/^@/, "")
                .replace(/z$/, "")
                .split(",")
                .map((coord) => parseFloat(coord));
            dispatch(
                setCenterAndZoom({
                    lat: coords[0],
                    lng: coords[1],
                    zoom: coords[2],
                })
            );
        }
        toggleSidebar();

        return () => {
            window.removeEventListener("resize", toggleSidebar);
        };
    }, []);

    const ls = window.localStorage;
    if (
        (!ls.hasOwnProperty("texasFloodDisclaimer") ||
            ls.texasFloodDisclaimer !== process.env.REACT_APP_VERSION) &&
        !disclaimerShown
    ) {
        setDisclaimerShown(true);
        dispatch(modalShown(DISCLAIMER));
    }

    return (
        <>
            <Header/>
            <GeocoderControl />
            <div id="mapRoot">
                <Map />
            </div>
            <ToastContainer />
            <Footer />
            <section id="controls-overlay">
              <AllModals />
              <Sidebar></Sidebar>
              <RadarAnimationControl />
            </section>
        </>
    );
}

export default App;
