import { useState } from "react";
import Modal from "./Modal";
import AboutShortContent from "./AboutShortContent";
import AboutLongContent from "./AboutLongContent";
import texasFloodLogo from "../images/texas_flood_logo_transparent_300x42.png";

import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { modalHidden } from "../redux/modalSlice";

// This component shows the About modal dialog

const About = () => {
    const dispatch = useDispatch();
    const [longContentShowing, setLongContentShowing] = useState(false);

    const handleClose = () => {
        dispatch(modalHidden());
    };

    return (
        <Modal>
            <div className="about">
                <div className="title">
                    <button onClick={handleClose}>
                        <FaTimes aria-label="close" />
                    </button>
                </div>
                <div className="body">
                    <div className="logo">
                        <a href="https://texasflood.org">
                            <img src={texasFloodLogo} />
                        </a>
                        <small>Version {process.env.REACT_APP_VERSION}</small>
                    </div>
                    <div className="about-content">
                        {!longContentShowing ? (
                            <AboutShortContent
                                showLongContent={() =>
                                    setLongContentShowing(true)
                                }
                                handleClose={handleClose}
                            />
                        ) : (
                            <AboutLongContent
                                showShortContent={() =>
                                    setLongContentShowing(false)
                                }
                                handleClose={handleClose}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default About;
