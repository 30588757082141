import L from "leaflet";
import keys from "../keys";
import { showPopup, hidePopup } from "../redux/mapSlice";
import { fetchAerisAlerts } from "../redux/aerisAlertsSlice";

// This is a class which defines the tile layer for the Weather Alerts feature layer

export default class WeatherAlertsLayer {
    constructor(id, zIndex, refreshTimeMs, reduxStore) {
        this.id = id;
        this.tileLayer = L.tileLayer(
            `https://maps.aerisapi.com/${keys.aerisApiId}_${keys.aerisApiSecret}/alerts/{z}/{x}/{y}/0.png`,
            {
                attribution:
                    '<a href="http://www.aerisweather.com">AerisWeather</a>',
                zIndex,
                opacity: 0.7,
                subdomains: "1234",
            }
        );

        this.refreshTimeMs = refreshTimeMs;
        this.reduxStore = reduxStore;
    }

    initialize(map) {
        // set up click handler for the popup
        map.on("click", (e) => {
            L.DomEvent.preventDefault(e);
            L.DomEvent.stopPropagation(e);

            if (map.hasLayer(this.tileLayer)) {
                this.reduxStore.dispatch(
                    fetchAerisAlerts({
                        lat: e.latlng.lat,
                        lng: e.latlng.lng,
                        eventTimeStamp: e.originalEvent.timeStamp,
                    })
                );
            }
        });
    }

    update() {
        // update the popup data
        this.tileLayer.redraw();
    }

    show(map) {
        map.addLayer(this.tileLayer);
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        this.refreshInterval = setInterval(() => {
            this.update();
        }, this.refreshTimeMs);
    }

    hide(map) {
        map.removeLayer(this.tileLayer);

        map.closePopup();
        this.reduxStore.dispatch(hidePopup());
        clearInterval(this.refreshInterval);
    }
}
