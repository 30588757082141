import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// this redux slice is concerned with state associated with the
// geocoder feature of this site

export const fetchGeocode = createAsyncThunk(
    "geocoder/fetchGeocode",
    async (query) => {
        const encodedQuery = encodeURIComponent(query);
        const url = `https://nominatim.tnris.org/search?q=${encodedQuery}&limit=5&format=json&addressdetails=1&countrycodes=us&viewbox=-115.02685546875%2C39.740986355883564%2C-84.70458984375%2C23.563987128451217&bounded=1`;
        const response = await fetch(url);
        const jsonResponse = await response.json();
        return jsonResponse;
    }
);

// results look like
// [
//   {
//     display_name,
//     boundingbox,
//     other unused fields
//   }
// ]
const initialState = {
    isGeocoderShowing: false,
    status: "idle",
    error: null,
    results: [],
};

export const getGeocoderShowing = (state) => state.geocoder.isGeocoderShowing;
export const getGeocoderResults = (state) => state.geocoder.results;

export const geocoderSlice = createSlice({
    name: "geocoder",
    initialState,
    reducers: {
        showGeocoder: (state, action) => {
            state.isGeocoderShowing = true;
        },
        hideGeocoder: (state, action) => {
            state.isGeocoderShowing = false;
            state.status = "idle";
            state.results = [];
            state.error = null;
        },
        selectGeocodeResult: (state, action) => {
            // in the case of more than one geocoding result,
            // when the user picks one, the results will be reset
            // to an array containing only the one result
            state.results = [action.payload];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchGeocode.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchGeocode.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.results = action.payload;
            })
            .addCase(fetchGeocode.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export const { showGeocoder, hideGeocoder, selectGeocodeResult } =
    geocoderSlice.actions;

export default geocoderSlice.reducer;
