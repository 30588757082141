import AWS from "aws-sdk";
import {
    CognitoSyncClient,
    ListRecordsCommand,
    UpdateRecordsCommand,
} from "@aws-sdk/client-cognito-sync";
import {
    CreateTopicCommand,
    PublishCommand,
    SNSClient,
    SubscribeCommand,
    UnsubscribeCommand,
} from "@aws-sdk/client-sns";
import keys from "../keys";

// these functions are concerned with retrieving and modifying
// gage subscriptions in AWS
// the Cognito Identity Pool contains an Identity and associated
// dataset which records SNS subcription ARNs of all the subscribed
// gages
// subscribing and unsubscribing involves modifying the SNS Topic
// subscriptions as well as the Cognito Identity dataset

const appConfig = { ...keys.awsConfig };

const datasetName = "texasflood";

// Retrieves the list of subscriptions in the Identity Pool dataset for this Identity
export const getSubscriptions = async () => {
    const syncClient = new CognitoSyncClient({
        region: AWS.config.region,
        credentials: AWS.config.credentials,
    });
    console.log(AWS.config.credentials.params.IdentityId);
    const syncCommand = new ListRecordsCommand({
        // ListRecordsRequest
        IdentityPoolId: appConfig.IdentityPoolId,
        IdentityId: AWS.config.credentials.params.IdentityId,
        DatasetName: datasetName,
    });
    const results = await syncClient.send(syncCommand);
    const subscriptions = await results.Records;
    const subscriptionData = {
        subscriptions,
        syncSessionToken: results.SyncSessionToken,
    };
    return subscriptionData;
};

const arrayToObject = (arr) =>
    arr.reduce(
        (obj, subscription) => ({ ...obj, [subscription.Key]: subscription }),
        {}
    );

// Subscribes to the SNS topic for the specified gage
const subscribeToTopic = async (lid, endpoint, protocol) => {
    const snsClient = new SNSClient({
        region: AWS.config.region,
        credentials: AWS.config.credentials,
    });

    const createResponse = await snsClient.send(
        new CreateTopicCommand({ Name: lid })
    );

    const response = await snsClient.send(
        new SubscribeCommand({
            TopicArn: createResponse.TopicArn,
            Protocol: protocol,
            Endpoint: endpoint,
        })
    );
    return [lid, response.SubscriptionArn];
};

export const subscribe = async (lids, endpoint, protocol, subscriptionData) => {
    const syncClient = new CognitoSyncClient({
        region: AWS.config.region,
        credentials: AWS.config.credentials,
    });

    const allPromises = [];
    lids.forEach((lid) => {
        allPromises.push(subscribeToTopic(lid, endpoint, protocol));
    });
    const results = await Promise.allSettled(allPromises);
    const recordPatches = [];
    const subscriptionsObj = arrayToObject(subscriptionData.subscriptions);
    results.forEach((result) => {
        const lid = result.value[0];
        const arn = result.value[1];
        const value = JSON.stringify({
            lid,
            subscriptionArn: arn,
            protocol,
            endpoint,
        });
        const subscription = subscriptionsObj[lid];
        recordPatches.push({
            Op: "replace",
            Key: lid,
            Value: value,
            SyncCount: subscription ? subscription.SyncCount : 0,
        });
    });
    const syncSessionToken = subscriptionData.syncSessionToken;
    const updateInput = {
        IdentityPoolId: appConfig.IdentityPoolId,
        IdentityId: AWS.config.credentials.params.IdentityId,
        DatasetName: datasetName,
        RecordPatches: recordPatches,
        SyncSessionToken: syncSessionToken,
    };
    const updateResponse = await syncClient.send(
        new UpdateRecordsCommand(updateInput)
    );
};

// Unsubscribes from the SNS Topic for the specified gage
const unsubscribeToTopic = async (arn) => {
    const snsClient = new SNSClient({
        region: AWS.config.region,
        credentials: AWS.config.credentials,
    });

    const response = await snsClient.send(
        new UnsubscribeCommand({
            SubscriptionArn: arn,
        })
    );
    return response;
};

// Iterates through the list of lids, unsubscribing them from the SNS Topic
// and removing them from the Identity dataset
export const unsubscribe = async (lids, subscriptionData) => {
    const syncClient = new CognitoSyncClient({
        region: AWS.config.region,
        credentials: AWS.config.credentials,
    });
    const syncSessionToken = subscriptionData.syncSessionToken;
    const subscriptionsObj = arrayToObject(subscriptionData.subscriptions);

    const recordPatches = [];
    lids.forEach(async (lid) => {
        const subscription = subscriptionsObj[lid];
        const arn = JSON.parse(subscription.Value).subscriptionArn;
        recordPatches.push({
            Op: "remove",
            Key: lid,
            SyncCount: subscription.SyncCount,
        });
        await unsubscribeToTopic(arn);
    });
    const updateInput = {
        IdentityPoolId: appConfig.IdentityPoolId,
        IdentityId: AWS.config.credentials.params.IdentityId,
        DatasetName: datasetName,
        RecordPatches: recordPatches,
        SyncSessionToken: syncSessionToken,
    };
    const updateResponse = await syncClient.send(
        new UpdateRecordsCommand(updateInput)
    );
};

// Send SNS message to use when a single gage has been subscribed to
// via the flood gage popup
export const sendSubscriptionMessage = async (lid) => {
    const snsClient = new SNSClient({
        region: AWS.config.region,
        credentials: AWS.config.credentials,
    });
    const createResponse = await snsClient.send(
        new CreateTopicCommand({ Name: lid })
    );
    const response = await snsClient.send(
        new PublishCommand({
            Message:
                `You have subscribed to the ${lid} flood gage.` +
                ` Visit ` +
                `https://${window.location.hostname}` +
                `/#/subscriptions to manage your flood gage subscriptions.`,
            TopicArn: createResponse.TopicArn,
        })
    );
};
