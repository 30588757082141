import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalHidden } from "../redux/modalSlice";
import Modal from "./Modal";
import SwitchBox from "../sidebar/SwitchBox";

// This component shows the disclaimer seen when the user first
// opens the app

const Disclaimer = () => {
    const dispatch = useDispatch();

    const [doNotShowAgain, setDoNotShowAgain] = useState(false);

    const handleClose = () => {
        dispatch(modalHidden());
        if (doNotShowAgain) {
            window.localStorage.setItem(
                "texasFloodDisclaimer",
                process.env.REACT_APP_VERSION
            );
        }
    };

    return (
        <Modal>
            <div className="disclaimer">
                <h3>Legal Review and Disclaimer</h3>
                <div className="body">
                    The data and information presented in this viewer is the
                    best available information provided to the Texas Water
                    Development Board (TWDB) by its data contributors. The
                    information may not be represented in real-time and should
                    not be considered as exact conditions in your area. Neither
                    the State of Texas nor the Texas Water Development Board
                    (TWDB) assumes any legal liability or responsibility or
                    makes any guarantees or warranties as to the accuracy,
                    completeness, or suitability of the information for any
                    purpose. By using this viewer or subscribing to a gage or
                    gages, you agree to release and hold harmless TWDB and its
                    respective representatives, agents, successors, assigns,
                    employees, officers and directors, from any and all
                    liability, for loss, harm, damage, injury, cost or expense
                    whatsoever including without limitation, property damage,
                    personal injury and/or death which may occur in connection
                    with the application service.
                </div>
                <button onClick={handleClose} autoFocus>
                    I HAVE READ AND AGREE TO THESE TERMS
                </button>
                <div className="dont-show">
                    <SwitchBox
                        name="doNotShowAgain"
                        onChange={() => setDoNotShowAgain((prev) => !prev)}
                        checked={doNotShowAgain}
                    />
                    <small>Do not show again</small>
                </div>
            </div>
        </Modal>
    );
};

export default Disclaimer;
