import { createSlice } from "@reduxjs/toolkit";

// this redux slice is concerned with the geolocation
// and geotracking features of this site

const initialState = {
    isGeolocationOn: false,
    isGeotrackingOn: false,
};

export const getGeolocationOn = (state) => state.geolocation.isGeolocationOn;
export const getGeotrackingOn = (state) => state.geolocation.isGeotrackingOn;

export const geolocationSlice = createSlice({
    name: "geolocation",
    initialState,
    reducers: {
        setGeolocation: (state, action) => {
            state.isGeolocationOn = action.payload;
        },
        setGeotracking: (state, action) => {
            state.isGeotrackingOn = action.payload;
        },
    },
});

export const { setGeolocation, setGeotracking } = geolocationSlice.actions;

export default geolocationSlice.reducer;
