import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    FORGOT_PASSWORD_FORM,
    SIGN_UP_FORM,
    currentFormChanged,
} from "../redux/userSlice";
import FormInput from "./FormInput";
import { login } from "../redux/userSliceThunks";

// this component is for the login form

const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [usernameAttributes, setUsernameAttributes] = useState({
        type: "text",
        min: 0,
        max: 50,
        pattern: "",
        label: "",
        error: "",
    });

    const dispatch = useDispatch();

    const handleChange = (e) => {
        const val = e.target.value;
        setUsername(val);
        if (val === "") {
            setUsernameAttributes({
                type: "text",
                min: 0,
                max: 50,
                pattern: "",
                label: "",
                error: "",
            });
        } else if (!isNaN(val.charAt(0))) {
            setUsernameAttributes({
                type: "tel",
                min: 10,
                max: 10,
                pattern: "[0-9]*",
                label: "Phone Number",
                error: "10 digits only including US area code",
            });
        } else {
            setUsernameAttributes({
                type: "text",
                min: 0,
                max: 50,
                pattern: "[A-Za-z0-9]*",
                label: "Username",
                error: "",
            });
        }
    };

    const handleSignIn = (e) => {
        e.preventDefault();
        dispatch(
            login({
                username: !isNaN(username.charAt(0))
                    ? "+1" + username
                    : username,
                password,
            })
        );
    };

    return (
        <div className="login-form">
            <p>
                Sign in to subscribe to flood gages and manage your current gage
                subscriptions
            </p>
            <form onSubmit={handleSignIn}>
                <FormInput
                    type={usernameAttributes.type}
                    placeholder="Username or Phone Number"
                    value={username}
                    changeHandler={handleChange}
                    pattern={usernameAttributes.pattern}
                    minLength={usernameAttributes.min}
                    maxLength={usernameAttributes.max}
                    error={usernameAttributes.error}
                    label={usernameAttributes.label}
                />
                <FormInput
                    type="password"
                    placeholder="Password"
                    value={password}
                    changeHandler={(e) => setPassword(e.target.value)}
                    label="Password"
                />
                <span className="submit-buttons">
                    <button type="submit">SIGN IN</button>
                </span>
            </form>
            <div className="login-other-options">
                Don&#39;t have an account?{" "}
                <a
                    href="#"
                    onClick={() => {
                        dispatch(currentFormChanged(SIGN_UP_FORM));
                    }}
                >
                    Sign Up
                </a>
            </div>
            <div className="login-other-options">
                <a
                    href="#"
                    onClick={() => {
                        dispatch(currentFormChanged(FORGOT_PASSWORD_FORM));
                    }}
                >
                    Forgot or Change Password
                </a>
            </div>
        </div>
    );
};

export default LoginForm;
