import React from "react";
import AboutCredits from "./AboutCredits";

const AboutShortContent = ({ showLongContent, handleClose }) => {
    return (
        <>
            <h3>Disclaimer</h3>
            <p className="about-disclaimer-text">
                The data and information presented in this viewer is the best
                available information provided to the Texas Water Development
                Board (TWDB) by its data contributors. The information may not
                be represented in real-time and should not be considered as
                exact conditions in your area. Neither the State of Texas nor
                the Texas Water Development Board (TWDB) assumes any legal
                liability or responsibility or makes any guarantees or
                warranties as to the accuracy, completeness, or suitability of
                the information for any purpose.
            </p>
            <h3 className="data-sources">Data Sources</h3>
            <p className="data-sources-list">
                Flood Gages:
                <br />
                <a href="https://water.weather.gov/ahps/" target="_blank">
                    National Weather Service
                </a>
                <br />
                Reservoir Conditions:
                <br />
                <a
                    href="https://waterdatafortexas.org/reservoirs/statewide"
                    target="blank"
                >
                    Texas Water Development Board
                </a>
                <br />
                Weather Alerts and Warnings:
                <br />
                <a href="https://www.aerisweather.com/" target="_blank">
                    Aeris Weather Service
                </a>
                <br />
                SMS Text Services:
                <br />
                <a href="https://aws.amazon.com/" target="_blank">
                    Amazon Web Services
                </a>
            </p>{" "}
            <button onClick={showLongContent}>More Detailed Information</button>
            <AboutCredits handleClose={handleClose} />
        </>
    );
};

export default AboutShortContent;
