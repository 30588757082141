import { useSelector, useDispatch } from "react-redux";
import {
    ACCOUNT_SETTINGS_FORM,
    currentFormChanged,
    gagesToUnsubscribeReset,
    getLids,
} from "../redux/userSlice";
import { logout } from "../redux/userSliceThunks";
import SubscriptionForm from "./SubscriptionForm";

// this component shows the gages the user is subcribed to and
// allows him or her to toggle them on and off

const Subcriptions = () => {
    let lids = useSelector(getLids);
    const dispatch = useDispatch();

    return (
        <div className="subscriptions">
            {lids.length === 0 ? (
                <p>
                    No alert subscriptions found. Click on a gage to sign up for
                    alerts.
                </p>
            ) : (
                <SubscriptionForm lids={lids} />
            )}
            <div className="nav-buttons">
                <button
                    onClick={() => {
                        dispatch(gagesToUnsubscribeReset());
                        dispatch(currentFormChanged(ACCOUNT_SETTINGS_FORM));
                    }}
                >
                    SETTINGS
                </button>
                <button
                    onClick={() => {
                        dispatch(logout());
                    }}
                >
                    SIGN OUT
                </button>
            </div>
        </div>
    );
};

export default Subcriptions;
