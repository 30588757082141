import { useId } from "react";

// this component is for form inputs on various forms for the user

const FormInput = ({
    type,
    placeholder,
    label,
    value,
    changeHandler,
    title,
    pattern,
    minLength,
    maxLength,
    error,
    style,
}) => {
    const inputId = useId();

    const showLabel = !!value;

    return (
        <>
            {showLabel && (
                <label htmlFor={inputId} className="form-label">
                    {label}
                </label>
            )}
            <input
                id={inputId}
                title={title}
                pattern={pattern}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={changeHandler}
                minLength={minLength}
                maxLength={maxLength}
                error={error}
                className="form-input"
                style={style}
            />{" "}
        </>
    );
};

export default FormInput;
