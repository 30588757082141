import { useState, useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { useSelector, useDispatch } from "react-redux";

import L from "leaflet";
import { getMapReady } from "../redux/mapSlice";
import {
    getGeolocationOn,
    getGeotrackingOn,
    setGeolocation,
    setGeotracking,
} from "../redux/geolocationSlice";
import { addToast } from "../redux/toasterSlice";
import {
    FaCompass,
    FaCrosshairs,
    FaMapMarkerAlt,
    FaTimes,
} from "react-icons/fa";
import defaultIconFile from "../images/marker.svg";
import watchLocationIconFile from "../images/crosshairs.svg";

// this hook adds the Geolocation behavior to the map,
// including the two map controls related to it
// geolocation marks the user's current location and
// geotracking follows the user

const useGeolocation = (leafletMap) => {
    const isMapReady = useSelector(getMapReady);
    const geolocationOn = useSelector(getGeolocationOn);
    const geotrackingOn = useSelector(getGeotrackingOn);
    const dispatch = useDispatch();

    const hasControlChanged = useRef(false);

    const geolocationMarker = useRef(null);
    const [markerCoords, setMarkerCoords] = useState(null);
    const [markerPopupContents, setMarkerPopupContents] = useState(null);
    const [geolocateButton, setGeolocateButton] = useState(null);
    const [geotrackingButton, setGeotrackingButton] = useState(null);

    useEffect(() => {
        if (isMapReady) {
            console.log("initializing geolocation controls");
            // set up geolocation and geotracking controls

            const geolocationOptions = {
                watch: false,
                setView: false,
                maximumAge: 10000,
                enableHighAccuracy: true,
            };

            const geotrackingButton = L.easyButton({
                states: [
                    {
                        stateName: "location-off",
                        icon: renderToString(
                            <span style={{ fontSize: "18px" }}>
                                <FaCompass />
                            </span>
                        ),
                        title: "Follow my location",
                        onClick: () => {
                            hasControlChanged.current = true;
                            leafletMap.locate({
                                ...geolocationOptions,
                                watch: true,
                            });
                            dispatch(setGeotracking(true));
                            dispatch(
                                addToast({
                                    msg: "Using the follow location feature on a mobile device will consume additional battery and data.",
                                })
                            );
                        },
                    },
                    {
                        stateName: "location-on",
                        icon: renderToString(
                            <span style={{ fontSize: "18px" }}>
                                <FaCrosshairs />
                            </span>
                        ),
                        title: "Stop following my location",
                        onClick: (control) => {
                            hasControlChanged.current = true;
                            leafletMap.stopLocate();
                            dispatch(setGeotracking(false));
                        },
                    },
                ],
            }).disable();

            const geolocateButton = L.easyButton({
                states: [
                    {
                        stateName: "zoom-to-location",
                        icon: renderToString(
                            <span style={{ fontSize: "18px" }}>
                                <FaMapMarkerAlt />
                            </span>
                        ),
                        title: "Find my location",
                        onClick: (control) => {
                            hasControlChanged.current = true;
                            leafletMap.locate(geolocationOptions);
                            dispatch(setGeolocation(true));
                        },
                    },
                    {
                        stateName: "reset-geolocation-tools",
                        icon: renderToString(
                            <span style={{ fontSize: "18px" }}>
                                <FaTimes />
                            </span>
                        ),
                        title: "Reset geolocation tools",
                        onClick: (control) => {
                            hasControlChanged.current = true;
                            leafletMap.stopLocate();
                            dispatch(setGeolocation(false));
                        },
                    },
                ],
            });

            setGeolocateButton(geolocateButton);
            setGeotrackingButton(geotrackingButton);

            const locateToolbar = L.easyBar(
                [geolocateButton, geotrackingButton],
                {
                    position: "bottomright",
                }
            );

            locateToolbar.addTo(leafletMap);

            // set back to default state if the app doesn't have
            // HTML5 geolocation permission
            leafletMap.on("locationerror", (e) => {
                console.log(e);
                dispatch(
                    addToast({
                        msg: "Error retrieving location. Please verify permission has been granted to your device or browser.",
                    })
                );
                hasControlChanged.current = true;
                dispatch(setGeolocation(false));
                dispatch(setGeotracking(false));
            });
        }
    }, [isMapReady]);

    // set up event handler to navigate to the geolocated point and
    // show the appropriate marker there.
    useEffect(() => {
        if (isMapReady) {
            const locationFoundHandler = (e) => {
                console.log("location found event", e);

                if (geolocationMarker.current) {
                    leafletMap.removeLayer(geolocationMarker.current);
                }

                const markerPopupContents =
                    `<div class="location-popup">` +
                    `<p class="title">Approximate Location</p>` +
                    `<p>Latitude: ${e.latitude.toPrecision(7)}</p>` +
                    `<p>Longitude: ${e.longitude.toPrecision(7)}</p>` +
                    `<p>Accuracy: ${e.accuracy.toLocaleString({
                        useGrouping: true,
                    })} meters</p>` +
                    `</div>`;
                setMarkerPopupContents(markerPopupContents);
                const markerCoords = e.latlng;
                setMarkerCoords(markerCoords);

                geolocationMarker.current = createGeolocationMarker(
                    markerCoords,
                    markerPopupContents,
                    geotrackingOn
                );

                leafletMap.addLayer(geolocationMarker.current);
                if (geolocationOn && !geotrackingOn) {
                    leafletMap.setView(markerCoords, 16);
                }
            };
            leafletMap.on("locationfound", locationFoundHandler);

            return () => {
                leafletMap.off("locationfound", locationFoundHandler);
            };
        }
    }, [isMapReady, geolocationOn, geotrackingOn]);

    // creates the map marker and adds it to the map
    const createGeolocationMarker = (coords, popupContents, geotrackingOn) => {
        const geolocationPopupOptions = {
            className: "geolocation-popup",
            closeButton: false,
        };

        const defaultIcon = L.icon({
            iconUrl: defaultIconFile,
            iconAnchor: [24, 44],
            popupAnchor: [0, -44],
        });

        const watchLocationIcon = L.icon({
            iconUrl: watchLocationIconFile,
            iconAnchor: [18, 20],
        });

        const marker = L.marker(coords, {
            icon: geotrackingOn ? watchLocationIcon : defaultIcon,
        });
        marker.bindPopup(popupContents, geolocationPopupOptions);

        return marker;
    };

    if (
        isMapReady &&
        geolocateButton &&
        geotrackingButton &&
        hasControlChanged.current
    ) {
        if (geolocationOn) {
            if (geotrackingOn) {
                geolocateButton.state("reset-geolocation-tools");
                geotrackingButton.state("location-on");
            } else {
                geotrackingButton.enable();
                geolocateButton.state("reset-geolocation-tools");
                geotrackingButton.state("location-off");
                // replace the geotracking icon with the geolocation icon,
                // if there is one
                if (geolocationMarker.current) {
                    leafletMap.removeLayer(geolocationMarker.current);
                    geolocationMarker.current = createGeolocationMarker(
                        markerCoords,
                        markerPopupContents,
                        geotrackingOn
                    );
                    leafletMap.addLayer(geolocationMarker.current);
                }
            }
        } else {
            leafletMap.stopLocate();
            if (geolocationMarker.current) {
                leafletMap.removeLayer(geolocationMarker.current);
            }
            geolocateButton.state("zoom-to-location");
            geotrackingButton.state("location-off");
            geotrackingButton.disable();
        }
    }
    hasControlChanged.current = false;
};

export default useGeolocation;
