import { RingLoader } from "react-spinners";

const Loading = () => {
    return (
        <div className="loading" title="loading">
            <RingLoader size={60} color={"#92C553"} loading={true} />
        </div>
    );
};

export default Loading;
