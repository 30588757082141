import twdbLogoImage from "../images/300w_TWDB_Primary_Horizontal_Digital_RGB.png";
import tnrisLogoImage from "../images/TxGIO_Primary_Horizontal_Digital_RGB_WebOptimized.png";

// this component is for showing the logos and close button
// at the bottom of the About modal
const AboutCredits = ({ handleClose }) => {
    return (
        <div className="credits">
            <p>Developed By</p>
            <div>
                <a href="https://www.twdb.texas.gov" target="_blank">
                    <img
                        src={twdbLogoImage}
                        alt="The Texas Water Development Board Logo"
                    />
                </a>
                <a href="https://geographic.texas.gov" target="_blank">
                    <img
                        src={tnrisLogoImage}
                        alt="Texas Natural Resources Information System logo"
                    />
                </a>
            </div>
            <button onClick={handleClose}>CLOSE</button>
        </div>
    );
};

export default AboutCredits;
