import { useEffect } from "react";
import { useSelector } from "react-redux";
import { WEATHER_RADAR } from "../layers/constants";
import { getActiveFeatureLayers } from "../redux/mapSlice";
import {
    getRadarAnimating,
    getWeatherRadarStatus,
} from "../redux/weatherRadarSlice";

// this hook controls the Weather Radar feature
// on the map

const useWeatherRadar = (leafletMap, featureLayers) => {
    const activeFeatureLayers = useSelector(getActiveFeatureLayers);
    const status = useSelector(getWeatherRadarStatus);
    const isAnimating = useSelector(getRadarAnimating);

    // code specific to the Radar feature layer if it's on
    useEffect(() => {
        const isRadarLayerShowing = activeFeatureLayers & WEATHER_RADAR;

        // listen for changes to the weather radar time stamps and instantiate new tile layers if so
        if (isRadarLayerShowing && status === "succeeded") {
            featureLayers["" + WEATHER_RADAR].setupLayers(leafletMap);
        }

        const radarLayer = featureLayers["" + WEATHER_RADAR];

        // toggle the animation
        // if it were playing and the layer is hidden,
        // hiding it will stop the animation, so we don't need to worry about that.
        if (isRadarLayerShowing) {
            isAnimating ? radarLayer.animate() : radarLayer.stopAnimation();
        }
    }, [activeFeatureLayers, status, isAnimating]);
};

export default useWeatherRadar;
