import { useState } from "react";
import { useDispatch } from "react-redux";
import { LOGIN_FORM, currentFormChanged } from "../redux/userSlice";
import FormInput from "./FormInput";
import { addToast } from "../redux/toasterSlice";
import { signUp } from "../redux/userSliceThunks";

// this component is for the form to create a user account

const SignUpForm = () => {
    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [hasPhoneConsent, setHasPhoneConsent] = useState(false);

    const handleSignUp = (e) => {
        e.preventDefault();
        if (!username) {
            dispatch(addToast({ msg: "Please enter a username" }));
            return;
        }
        if (!email && !hasPhoneConsent) {
            dispatch(addToast({ msg: "Please enter your email address" }));
            return;
        }
        if (!password) {
            dispatch(addToast({ msg: "Please enter a password" }));
            return;
        }
        if (password !== confirmPassword) {
            dispatch(addToast({ msg: "Passwords do not match" }));
            setPassword("");
            setConfirmPassword("");
            return;
        }
        if (!phone && hasPhoneConsent) {
            dispatch(addToast({ msg: "Please enter your phone number" }));
            return;
        }

        dispatch(signUp({ username, password, email, phone }));
    };

    return (
        <div className="sign-up-form">
            <p>
                Sign up for an account to subscribe to flood gages and begin
                receiving alerts when they enter elevated flood stages. If you
                would like Text Message alerts then provide a phone number
            </p>
            <form onSubmit={handleSignUp}>
                <FormInput
                    label="Username"
                    pattern="[^0-9\s]\S*"
                    title="Username must not start with a number or contain spaces"
                    type="text"
                    placeholder="Username"
                    value={username}
                    changeHandler={(e) => {
                        setUsername(e.target.value);
                    }}
                />
                <FormInput
                    label="Email"
                    type="text"
                    placeholder="Email"
                    value={email}
                    changeHandler={(e) => {
                        setEmail(e.target.value);
                    }}
                />
                <FormInput
                    label="Password"
                    pattern=".{6,}"
                    minLength={6}
                    title="Minimum 6 characters."
                    type="password"
                    placeholder="Password"
                    value={password}
                    changeHandler={(e) => {
                        setPassword(e.target.value);
                    }}
                />
                <FormInput
                    label="Confirm Password"
                    pattern={password}
                    minLength={6}
                    title="Passwords do not match."
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    changeHandler={(e) => {
                        setConfirmPassword(e.target.value);
                    }}
                />
                <FormInput
                    style={{ display: hasPhoneConsent ? "inline" : "none" }}
                    label="Mobile Phone Number"
                    type="tel"
                    pattern="[0-9]*"
                    minLength={10}
                    maxLength={10}
                    title="10 digits only including US area code"
                    placeholder="Mobile Phone Number"
                    value={phone}
                    changeHandler={(e) => {
                        setPhone(e.target.value);
                    }}
                />
                <div>
                    <input
                        type="checkbox"
                        onChange={() => {
                            setHasPhoneConsent((prev) => !prev);
                        }}
                        id="consentCheck"
                    />{" "}
                    <label htmlFor="consentCheck">
                        Check this box to consent to receive text alerts.
                    </label>
                </div>
                <span className="submit-buttons">
                    <button type="submit">SIGN UP</button>
                </span>
            </form>
            <div className="login-other-options">
                <p>
                    Already have an account?{" "}
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(currentFormChanged(LOGIN_FORM));
                        }}
                    >
                        Sign In
                    </a>
                </p>
            </div>
        </div>
    );
};

export default SignUpForm;
