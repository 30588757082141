import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { HashRouter, Route, Routes } from "react-router-dom";
// import CurrentFloodConditions from "./accessibility/CurrentFloodConditions";
import ReactGA from "./util/googleAnalytics";

ReactGA.pageview(window.location.pathname);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <Routes>
                    <Route path="/gage/:lid" element={<App />} />
                    <Route path="/map/:coords" element={<App />} />
                    <Route path="/subscriptions" element={<App />} />
                    {/* <Route
                        path="/currentFloodConditions"
                        element={<CurrentFloodConditions />}
                    /> */}
                    <Route path="/*" element={<App />} />
                </Routes>
            </HashRouter>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
