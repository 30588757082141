import { createSlice } from "@reduxjs/toolkit";
import { STREETS, CUSTOM_OVERLAY } from "../layers/constants";
import { setCustomGeoJSONLayer } from "./geoJsonSlice";

// this redux slice is concerned with map state external to
// the Leaflet Map object itself and accessible to other components

// activeFeatureLayers is a bitwise flag register.
// Bit 1 is Flood Guages, 2 is Lake Conditions, 3 is Weather Alerts, 4 is Weather Radar, 5 is State Parks, 6 is custom overlay
// See layers.js for the bit mask constants
const initialState = {
    isMapReady: false,
    currentCoords: {
        zoom: 4,
        lat: 31,
        lng: -100,
    },
    baseLayer: STREETS,
    activeFeatureLayers: 1, // Flood gages is visible by default
    isPopupShowing: false,
    popupData: null,
    lastPopupEventTimeStamp: 0,
    isConfirmSubscribeShowing: false,
};

export const getMapReady = (state) => state.map.isMapReady;
export const getCurrentCoords = (state) => state.map.currentCoords;
export const getCurrentBaseLayer = (state) => state.map.baseLayer;
export const getActiveFeatureLayers = (state) => state.map.activeFeatureLayers;
export const getPopupShowing = (state) => state.map.isPopupShowing;
export const getPopupData = (state) => state.map.popupData;
export const getConfirmSubscribeShowing = (state) =>
    state.map.isConfirmSubscribeShowing;

export const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
        setMapReady: (state, action) => {
            state.isMapReady = action.payload;
        },
        setCenterAndZoom: (state, action) => {
            const zoom = state.currentCoords.zoom;
            state.currentCoords = action.payload;
            if (!action.payload.zoom) {
                state.currentCoords.zoom = zoom;
            }
        },
        changeBaseLayer: (state, action) => {
            state.baseLayer = action.payload;
        },
        toggleActiveFeatureLayer: (state, action) => {
            state.activeFeatureLayers =
                state.activeFeatureLayers ^ action.payload;
        },
        showPopup: (state, action) => {
            // we store the click event timestamp in the store to
            // prevent more than one popup from appearing from the
            // same event
            if (
                !action.payload.eventTimeStamp ||
                action.payload.eventTimeStamp !== state.lastPopupEventTimeStamp
            ) {
                state.isPopupShowing = true;
                state.popupData = action.payload;
                state.lastPopupEventTimeStamp = action.payload.eventTimeStamp;
            }
        },
        hidePopup: (state, action) => {
            state.isPopupShowing = false;
            state.popupData = null;
        },
        showConfirmSubscribed: (state, action) => {
            state.isConfirmSubscribeShowing = true;
        },
        hideConfirmSubscribed: (state, action) => {
            state.isConfirmSubscribeShowing = false;
        },
    },
    extraReducers(builder) {
        builder.addCase(setCustomGeoJSONLayer.type, (state, action) => {
            // hide the custom feature layer if the JSON is null
            if (!action.payload) {
                state.activeFeatureLayers =
                    state.activeFeatureLayers & ~CUSTOM_OVERLAY;
            }
        });
    },
});

export const {
    setMapReady,
    setCenterAndZoom,
    changeBaseLayer,
    toggleActiveFeatureLayer,
    showPopup,
    hidePopup,
    dataRefreshed,
    showConfirmSubscribed,
    hideConfirmSubscribed,
} = mapSlice.actions;

export default mapSlice.reducer;
