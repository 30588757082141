import PopupTitle from "./PopupTitle";
import { getPopupData, showConfirmSubscribed } from "../redux/mapSlice";
import { useSelector, useDispatch } from "react-redux";
import { getAuthenticated } from "../redux/userSlice";
import { addToast } from "../redux/toasterSlice";
import icon from "../images/flood_gauge_icon.png";
import { FaRegEnvelope, FaFacebookF, FaTwitter } from "react-icons/fa";
import { CONFIRM_SUBSCRIPTION, modalShown } from "../redux/modalSlice";

// this component is for the leaflet popup which appears
// when the user clicks on a flood gage, or zooms to it
// by clicking on a subscribed gage in the subscriptions list

const FloodGagePopup = ({ handleLoaded, handleClose }) => {
    const popupData = useSelector(getPopupData);
    const authenticated = useSelector(getAuthenticated);
    const dispatch = useDispatch();

    // TODO: refresh contents when data
    const hydrographImage = `https://water.noaa.gov/resources/hydrographs/${popupData.lid.toLowerCase()}_hg.png`;
    // const gaugeLink = `https://water.weather.gov/ahps2/hydrograph.php?wfo=${popupData.wfo.toLowerCase()}&gage=${popupData.lid.toLowerCase()}`;
    const gaugeLink = `https://water.noaa.gov/gauges/${popupData.lid.toLowerCase()}`;

    const formatTime = (dateObj) => {
        // https://gist.github.com/hjst/1326755
        // formats a javascript Date object into a 12h AM/PM time string
        let hour = dateObj.getUTCHours();
        let minute = dateObj.getMinutes();
        const amPM = hour > 11 ? "pm" : "am";
        if (hour > 12) {
            hour -= 12;
        } else if (hour == 0) {
            hour = "12";
        }
        if (minute < 10) {
            minute = "0" + minute;
        }
        return hour + ":" + minute + amPM;
    };

    const shareUrl = `https://${window.location.hostname}/#/gage/${popupData.lid}`;
    let shareTitle;
    let timestamp;
    if (popupData.timestamp) {
        const date = new Date(popupData.timestamp);
        timestamp = formatTime(date);
    }

    if (
        ["action", "flood", "moderate", "major"].indexOf(popupData.sigstage) !==
        -1
    ) {
        shareTitle = `@TexasFloodOrg ${timestamp} (UTC) ${popupData.name} (${
            popupData.lid
        }) - Status: ${popupData.sigstage.toUpperCase()} at ${
            popupData.stage
        } feet`;
    } else {
        shareTitle = `@TexasFloodOrg ${timestamp} (UTC) ${popupData.name} (${popupData.lid}) - Stage: ${popupData.stage} feet`;
    }

    const handleSubscribeClicked = () => {
        if (authenticated) {
            dispatch(modalShown(CONFIRM_SUBSCRIPTION));
        } else {
            dispatch(
                addToast({
                    msg: "Sign in to your account to add new subscriptions.",
                })
            );
        }
    };

    return (
        <div>
            <PopupTitle
                icon={icon}
                title="Flood Gage"
                handleClose={handleClose}
                handleSubscribeClicked={handleSubscribeClicked}
            />
            <div>
                <p className="flood-gage-name">
                    {popupData.name} ({popupData.lid})
                </p>
                <a href={gaugeLink} target="flood-gage-details">
                    <img
                        src={hydrographImage}
                        onLoad={handleLoaded}
                        alt="Click here to be taken to the NOAA web page for this flood gage"
                    />
                </a>
            </div>
            {/* {sharebar} */}
            <div className="share-bar">
                <span>
                    <a
                        href={`mailto:?subject=${shareTitle}&body=${shareUrl}`}
                        aria-label="email"
                        style={{ backgroundColor: "#7f7f7f" }}
                    >
                        <FaRegEnvelope />
                    </a>
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${shareTitle}&hashtag=TexasFlood`}
                        aria-label="facebook"
                        style={{ backgroundColor: "#3b5998" }}
                    >
                        <FaFacebookF />
                    </a>
                    <a
                        href={`https://twitter.com/share?url=${shareUrl}&text=${shareTitle}&hashtags=TexasFlood`}
                        aria-label="twitter"
                        style={{ backgroundColor: "#00aced" }}
                    >
                        <FaTwitter />
                    </a>
                </span>
            </div>
        </div>
    );
};

export default FloodGagePopup;
