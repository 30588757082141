import { useDispatch } from "react-redux";
import Modal from "./Modal";
import { FaTrash } from "react-icons/fa";
import { modalHidden } from "../redux/modalSlice";
import { deleteAccount } from "../redux/userSliceThunks";

// This component confirms the user truly wants to delete the account

const DeleteAccount = () => {
    const dispatch = useDispatch();

    const handleDeleteAccount = () => {
        dispatch(modalHidden());
        dispatch(deleteAccount());
    };

    return (
        <Modal>
            <p className="title">
                <FaTrash className="icon" />
                Delete Account
            </p>
            <p className="body">
                Are you sure you want to delete your account?
            </p>
            <div className="buttons">
                <button
                    onClick={() => {
                        dispatch(modalHidden());
                    }}
                >
                    CANCEL
                </button>
                <button onClick={handleDeleteAccount}>CONFIRM</button>
            </div>
        </Modal>
    );
};

export default DeleteAccount;
