import { createSlice } from "@reduxjs/toolkit";
import {
    alertsChanged,
    confirmNewPassword,
    deleteAccount,
    forgotPassword,
    login,
    logout,
    resendCode,
    retrieveAccount,
    signUp,
    subscriptionsChanged,
    verifyCode,
} from "./userSliceThunks";

// this Redux slice is concerned with functionality surrounding the
// AWS Cognito user
// it manages the state changes that happen when the user logs in and out, etc.

export const LOGIN_FORM = "login";
export const ACCOUNT_SETTINGS_FORM = "account";
export const SUBSCRIPTIONS_FORM = "subscriptions";
export const SIGN_UP_FORM = "signup";
export const FORGOT_PASSWORD_FORM = "forgotPassword";
export const VERIFY_CODE = "verify";
export const RESET_PASSWORD_FORM = "resetPassword";

// lids is an array of strings of flood gage ids, e.g. ["FOO1", "FOO2"]
// gagesToUnsubscribe is a subset of lids, containing
//   any to unsubscribe which haven't been unsubscribed yet
// currentForm is the form component displayed in the
//   UserControls section of the Sidebar component
const initialState = {
    status: "idle",
    error: null,
    authenticated: false,
    username: "",
    signUpPassword: "",
    email: "",
    phone: "",
    currentAlerts: true,
    predictiveAlerts: true,
    lids: [],
    gagesToUnsubscribe: [],
    newCurrentAlerts: true,
    newPredictiveAlerts: true,
    currentForm: LOGIN_FORM,
};

export const getStatus = (state) => state.user.status;
export const getAuthenticated = (state) => state.user.authenticated;
export const getUsername = (state) => state.user.username;
export const getPhone = (state) => state.user.phone;
export const getEmail = (state) => state.user.email;
export const getCurrentAlerts = (state) => state.user.currentAlerts;
export const getPredictiveAlerts = (state) => state.user.predictiveAlerts;
export const getLids = (state) => state.user.lids;
export const getCurrentForm = (state) => state.user.currentForm;

export const getGagesToUnsubscribe = (state) => state.user.gagesToUnsubscribe;
export const getNewCurrentAlerts = (state) => state.user.newCurrentAlerts;
export const getNewPredictiveAlerts = (state) => state.user.newPredictiveAlerts;

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        currentFormChanged: (state, action) => {
            state.currentForm = action.payload;
        },
        gageToUnsubscribeAdded: (state, action) => {
            state.gagesToUnsubscribe.push(action.payload);
        },
        gageToUnsubscribeRemoved: (state, action) => {
            state.gagesToUnsubscribe = state.gagesToUnsubscribe.filter(
                (gage) => gage !== action.payload
            );
        },
        gagesToUnsubscribeReset: (state, action) => {
            state.gagesToUnsubscribe = [];
        },
        newCurrentAlertsChanged: (state, action) => {
            state.newCurrentAlerts = action.payload;
        },
        newPredictiveAlertsChanged: (state, action) => {
            state.newPredictiveAlerts = action.payload;
        },
        newAlertsReset: (state, action) => {
            state.newCurrentAlerts = state.currentAlerts;
            state.newPredictiveAlerts = state.predictiveAlerts;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(login.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(login.fulfilled, (state, action) => {
                return {
                    ...state,
                    status: "succeeded",
                    currentForm: SUBSCRIPTIONS_FORM,
                    gagesToUnsubscribe: [],
                    newCurrentAlerts: action.payload.currentAlerts,
                    newPredictiveAlerts: action.payload.predictiveAlerts,
                    ...action.payload,
                };
            })
            .addCase(login.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(alertsChanged.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(alertsChanged.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.currentAlerts = action.payload.current;
                state.predictiveAlerts = action.payload.predictive;
                state.newCurrentAlerts = action.payload.current;
                state.newPredictiveAlerts = action.payload.predictive;
            })
            .addCase(alertsChanged.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(subscriptionsChanged.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(subscriptionsChanged.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.lids = action.payload.lids;
                state.gagesToUnsubscribe = [];
            })
            .addCase(subscriptionsChanged.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(logout.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(logout.fulfilled, (state, action) => {
                return {
                    ...initialState,
                    status: "succeeded",
                };
            })
            .addCase(logout.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(signUp.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(signUp.fulfilled, (state, action) => {
                return {
                    ...state,
                    status: "succeeded",
                    currentForm: VERIFY_CODE,
                    ...action.payload,
                };
            })
            .addCase(signUp.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(verifyCode.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(verifyCode.fulfilled, (state, action) => {
                return {
                    ...state,
                    signUpPassword: "",
                    status: "succeeded",
                    authenticated: true,
                    currentForm: SUBSCRIPTIONS_FORM,
                };
            })
            .addCase(verifyCode.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                if (action.payload.aliasExists) {
                    state.currentForm = LOGIN_FORM;
                }
            })
            .addCase(resendCode.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(resendCode.fulfilled, (state, action) => {
                return {
                    ...state,
                    status: "succeeded",
                    currentForm: VERIFY_CODE,
                };
            })
            .addCase(resendCode.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(forgotPassword.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                return {
                    ...state,
                    status: "succeeded",
                    currentForm: RESET_PASSWORD_FORM,
                };
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(confirmNewPassword.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(confirmNewPassword.fulfilled, (state, action) => {
                return {
                    ...state,
                    status: "succeeded",
                    currentForm: LOGIN_FORM,
                };
            })
            .addCase(confirmNewPassword.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(deleteAccount.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(deleteAccount.fulfilled, (state, action) => {
                return {
                    ...initialState,
                    status: "succeeded",
                };
            })
            .addCase(deleteAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(retrieveAccount.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(retrieveAccount.fulfilled, (state, action) => {
                if (action.payload) {
                    return {
                        ...state,
                        status: "succeeded",
                        currentForm: SUBSCRIPTIONS_FORM,
                        ...action.payload,
                    };
                } else {
                    return {
                        ...initialState,
                        status: "succeeded",
                    };
                }
            })
            .addCase(retrieveAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export const {
    currentFormChanged,
    gageToUnsubscribeAdded,
    gageToUnsubscribeRemoved,
    gagesToUnsubscribeReset,
    newCurrentAlertsChanged,
    newPredictiveAlertsChanged,
    newAlertsReset,
} = userSlice.actions;

export default userSlice.reducer;
