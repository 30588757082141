import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PopupTitle from "./PopupTitle";
import { getPopupData } from "../redux/mapSlice";
import icon from "../images/tree.png";

// this component is for the Leaflet popup which appears when you
// click on one of the state parks or wildlife management areas

const parkNameStyles = {
    fontSize: "24px",
    textAlign: "center",
    margin: "25px 0",
};

const summaryStyles = {
    padding: "12px",
};

const StateParkPopup = ({ handleClose }) => {
    const popupData = useSelector(getPopupData);
    const [summary, setSummary] = useState("");

    useEffect(() => {
        const getSummary = async (parkName) => {
            try {
                const encodedParkName = encodeURIComponent(
                    parkName.split("-")[0]
                );
                const quickSearch = await fetch(
                    `https://en.wikipedia.org/w/api.php?action=query&list=search&srsearch=${encodedParkName}+incategory:State_parks_of_Texas|Mountain_ranges_of_Texas|Rivers_of_Texas|Reservoirs_in_Texas|Rock_formations_of_Texas|National_Forests_of_Texas&utf8=&format=json&origin=*`
                );
                const result = await quickSearch.json();
                if (result.query.search.length) {
                    const page = await fetch(
                        `https://en.wikipedia.org/w/api.php?format=json&action=query&prop=extracts&exintro&explaintext&redirects=1&pageids=${result.query.search[0].pageid}&origin=*`
                    );
                    const summary = await page.json();
                    setSummary(
                        JSON.stringify(
                            summary.query.pages[result.query.search[0].pageid]
                                .extract
                        )
                    );
                } else {
                    setSummary("");
                }
            } catch (e) {
                setSummary("");
            }
        };
        getSummary(popupData.parkName);
    }, [popupData.parkName]);

    return (
        <div>
            <PopupTitle
                icon={icon}
                title={popupData.title}
                handleClose={handleClose}
            />
            <div style={parkNameStyles}>{popupData.parkName}</div>
            <div style={summaryStyles}>{summary}</div>
        </div>
    );
};

export default StateParkPopup;
