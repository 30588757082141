import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// toasts looks like
// [
//     {
//         msg, (the actual message)
//         duration,  (how long it should appear before animating out)
//         id, (an id - set to the currentId which is then iterated)
//         status, ("begin" | "showing" | "end")
//     }
// ]
const initialState = {
    toasts: [],
    currentId: 0,
};

export const getToasts = (state) => state.toaster.toasts;

const toasterSlice = createSlice({
    name: "toaster",
    initialState,
    reducers: {
        addToast: (state, action) => {
            const newToast = {
                msg: action.payload.msg,
                duration: action.payload.duration || 3000,
                id: state.currentId,
                status: "begin",
            };
            // set toasts = to new array to force a refresh
            state.toasts = [newToast, ...state.toasts];
            state.currentId += 1;
        },
        updateToast: (state, action) => {
            state.toasts = state.toasts.map((toast) =>
                toast.id === action.payload.id ? action.payload : toast
            );
        },
        removeToast: (state, action) => {
            // set toasts = to new array to force a refresh
            state.toasts = state.toasts.filter(
                (toast) => toast.id !== action.payload
            );
        },
    },
});

export const { addToast, updateToast, removeToast } = toasterSlice.actions;

export default toasterSlice.reducer;
