import { useSelector } from "react-redux";
import {
    ACCOUNT_SETTINGS_FORM,
    FORGOT_PASSWORD_FORM,
    RESET_PASSWORD_FORM,
    SIGN_UP_FORM,
    SUBSCRIPTIONS_FORM,
    VERIFY_CODE,
    getCurrentForm,
    getStatus,
    getUsername,
} from "../redux/userSlice";
import LoginForm from "./LoginForm";
import Subscriptions from "./Subscriptions";
import AccountSettings from "./AccountSettings";
import SignUpForm from "./SignUpForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import VerifyConfirmationCode from "./VerifyConfirmationCode";
import ResetPasswordForm from "./ResetPasswordForm";
import Loading from "./Loading";

// this component contains and controls the display of the various
// user forms, like login, signup, etc.

const UserControls = () => {
    const status = useSelector(getStatus);
    const username = useSelector(getUsername);
    const currentForm = useSelector(getCurrentForm);

    console.log("current form", currentForm);

    let content;
    // user status or subscription status
    if (status === "pending") {
        content = <Loading />;
    } else if (username && currentForm === SUBSCRIPTIONS_FORM) {
        content = <Subscriptions />;
    } else if (username && currentForm === ACCOUNT_SETTINGS_FORM) {
        content = <AccountSettings />;
    } else if (!username && currentForm === SIGN_UP_FORM) {
        content = <SignUpForm />;
    } else if (!username && currentForm === FORGOT_PASSWORD_FORM) {
        content = <ForgotPasswordForm />;
    } else if (username && currentForm === VERIFY_CODE) {
        content = <VerifyConfirmationCode />;
    } else if (!username && currentForm === RESET_PASSWORD_FORM) {
        content = <ResetPasswordForm />;
    } else {
        content = <LoginForm />;
    }

    return <div className="user-control-form">{content}</div>;
};

export default UserControls;
