import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import keys from "../keys";
import { showPopup } from "./mapSlice";
import { WEATHER_ALERTS } from "../layers/constants";

// This redux slice is concerned with state related to
// NOAA Aeris weather alerts (floods, tornados, etc.)

export const fetchAerisAlerts = createAsyncThunk(
    "aerisAlerts/fetchAerisAlerts",
    async ({ lat, lng, eventTimeStamp }, thunkAPI) => {
        const response = await fetch(
            `https://api.aerisapi.com/alerts/${lat},${lng}?limit=100&query=active:1&sort=sigp&client_id=${keys.aerisApiId}&client_secret=${keys.aerisApiSecret}`
        );
        const result = await response.json();
        if (result.response.length > 0) {
            const alerts = result.response.map((alert) => {
                return { name: alert.details.name, body: alert.details.body };
            });
            const payload = {
                id: WEATHER_ALERTS,
                lat,
                lng,
                alerts,
                eventTimeStamp,
            };
            thunkAPI.dispatch(showPopup(payload));
        }
    }
);

const initialState = {
    status: "idle",
    error: null,
};

export const getAerisAlertStatus = (state) => state.aerisAlerts.status;

const aerisAlertsSlice = createSlice({
    name: "aerisAlerts",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchAerisAlerts.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAerisAlerts.fulfilled, (state, action) => {
                state.status = "succeeded";
            })
            .addCase(fetchAerisAlerts.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default aerisAlertsSlice.reducer;
