import L from "leaflet";
import "../vendor";
import keys from "../keys";
import UtfGridLayer from "./UtfGridLayer";
import {
    STREETS,
    SATELLITE,
    FLOOD_GAGES,
    LAKE_CONDITIONS,
    WEATHER_ALERTS,
    WEATHER_RADAR,
    STATE_PARKS,
    CUSTOM_OVERLAY,
} from "./constants";
import WeatherAlertsLayer from "./WeatherAlertsLayer";
import WeatherRadarLayer from "./WeatherRadarLayer";
import store from "../redux/store";
import GeoJsonLayer from "./GeoJsonLayer";

import floodGaugeIcon from "../images/flood_gauge_icon.png";
import floodGaugeLegend from "../images/nws-ahps-gauges-texas-legend.png";
import lakeIcon from "../images/boat_icon.png";
import lakeLegend from "../images/reservoir-conditions-legend.png";
import weatherAlertIcon from "../images/flood_alert_red.png";
import weatherAlertLegend from "../images/flood-alert-legend.jpg";
import weatherIcon from "../images/weather_icon.png";
import weatherLegend from "../images/animated-weather-legend_800x450.png";
import treeIcon from "../images/tree.png";
import stateParkLegend from "../images/state-park-legend.png";
import customOverlayIcon from "../images/custom_button.png";
import customLayerLegend from "../images/custom-layer-legend.png";

// This module instantiates the base and feature layers
// (which are treated like constants from React's point of view)

export const leafletBaseLayers = {
    [STREETS]: L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
        {
            attribution:
                "Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012",
            detectRetina: false,
            layerId: "esri-world-streetmap",
            zIndex: 1,
        }
    ),
    [SATELLITE]: L.tileLayer.wmts(
        "https://txgi.tnris.org/login/path/spoon-java-neuron-nebula/wmts/",
        {
            layerId: "tx-goog",
            layer: "texas",
            style: "default",
            tilematrixSet: "0to20",
            format: "image/png",
            version: "1.0.0",
            serviceMode: "KVP",
            bounds: L.latLngBounds([
                [25.601902, -107.050781],
                [36.633162, -93.208007],
            ]),
            attribution: "Imagery &copy; 2016 Google",
            zIndex: 1,
        }
    ),
};

export const featureLayers = {
    [FLOOD_GAGES]: new UtfGridLayer(
        FLOOD_GAGES, // id
        "nws_ahps_gauges_texas", // mapFile
        "CurrentStage", // interactivity
        "nws_ahps_gauges_texas_nobuff", // nobuff
        '<a href="http://water.weather.gov/ahps/">NOAA National Weather Service</a>', // attribution
        7, //zIndex
        300000, // refreshTimeMs (5 minutes)
        [
            "lid",
            "latitude",
            "longitude",
            "name",
            "stage",
            "sigstage",
            "wfo",
            "timestamp",
        ], //popupPayloadParameters
        store // redux store
    ),
    [LAKE_CONDITIONS]: new UtfGridLayer(
        LAKE_CONDITIONS, // id
        "wdft_reservoir_conditions", // mapFile
        "Reservoirs", // interactivity
        null, // nobuff
        '<a href="http://waterdatafortexas.org/">WaterDataForTexas.org</a>', // attribution
        3, // zIndex
        1800000, // refreshTimeMs (30 minutes)
        ["lake_url_name"], //popupPayloadParameters
        store // redux store
    ),
    [WEATHER_ALERTS]: new WeatherAlertsLayer(
        WEATHER_ALERTS, //id
        2, // zIndex
        60000, // refreshTimeMs (1 minute)
        store // redux store
    ),
    [WEATHER_RADAR]: new WeatherRadarLayer(
        WEATHER_RADAR, // id
        `https://maps{s}.aerisapi.com/${keys.aerisApiId}_${keys.aerisApiSecret}/radar/{z}/{x}/{y}/`, // baseUrl
        4, // zIndex
        360000, // refreshTimeMs (6 minutes)
        store // redux store
    ),
    [STATE_PARKS]: new GeoJsonLayer(
        STATE_PARKS, // id
        [
            // urls
            {
                url: "https://services1.arcgis.com/1mtXwieMId59thmg/ArcGIS/rest/services/Texas_State_Parks_Boundaries/FeatureServer/0/query?f=geojson&where=1%3D1&returnGeometry=true",
                bgColor: "green",
            },
            {
                url: "https://services1.arcgis.com/1mtXwieMId59thmg/arcgis/rest/services/WMA_Boundaries_4PublicDistribution/FeatureServer/0/query?f=geojson&where=1%3D1&returnGeometry=true",
                bgColor: "#254524",
            },
        ],
        5, // zIndex
        7600000, // refreshTimeMs (~127 minutes)
        store
    ),
    [CUSTOM_OVERLAY]: new GeoJsonLayer(
        CUSTOM_OVERLAY, // id
        null,
        6, // zIndex
        7600000, // refreshTimeMs (~127 minutes)
        store
    ),
};

export const sidebarProperties = {
    [FLOOD_GAGES]: {
        text: "Flood Gages",
        icon: floodGaugeIcon,
        altText: "Flood Gage Icon",
        legend: floodGaugeLegend,
    },
    [LAKE_CONDITIONS]: {
        text: "Lake Conditions",
        icon: lakeIcon,
        altText: "Lake Icon",
        legend: lakeLegend,
    },
    [WEATHER_ALERTS]: {
        text: "Weather Alerts",
        icon: weatherAlertIcon,
        altText: "Flood Alerts Icon",
        legend: weatherAlertLegend,
    },
    [WEATHER_RADAR]: {
        text: "Weather Radar",
        icon: weatherIcon,
        altText: "Weather Icon",
        legend: weatherLegend,
    },
    [STATE_PARKS]: {
        text: "State Parks and WMA",
        icon: treeIcon,
        altText: "Park Icon",
        legend: stateParkLegend,
    },
    [CUSTOM_OVERLAY]: {
        text: "Custom Overlay",
        icon: customOverlayIcon,
        altText: "Custom Overlay Icon",
        legend: customLayerLegend,
    },
};
