import { useSelector } from "react-redux";
import {
    ABOUT,
    CONFIRM_SUBSCRIPTION,
    CONTACT,
    DELETE_ACCOUNT,
    DISCLAIMER,
    SAVE_ALERT_CHANGES,
    SAVE_SUBSCRIPTION_CHANGES,
    getCurrentModal,
} from "../redux/modalSlice";
import Disclaimer from "./Disclaimer";
import About from "./About";
import Contact from "./Contact";
import SaveAlertChanges from "./SaveAlertChanges";
import SaveSubscriptionChanges from "./SaveSubscriptionChanges";
import ConfirmSubscription from "./ConfirmSubscription";
import DeleteAccount from "./DeleteAccount";

// This component groups all the modals together to
// attach to the App component.  This is done because
// modals used position: fixed which doesn't work if
// they come after a component which uses transitions.

const AllModals = () => {
    const currentModal = useSelector(getCurrentModal);

    return (
        <>
            {currentModal === DISCLAIMER && <Disclaimer />}
            {currentModal === ABOUT && <About />}
            {currentModal === CONTACT && <Contact />}
            {currentModal === SAVE_ALERT_CHANGES && <SaveAlertChanges />}
            {currentModal === SAVE_SUBSCRIPTION_CHANGES && (
                <SaveSubscriptionChanges />
            )}
            {currentModal === CONFIRM_SUBSCRIPTION && <ConfirmSubscription />}
            {currentModal === DELETE_ACCOUNT && <DeleteAccount />}
        </>
    );
};

export default AllModals;
