import React from "react";
import { sidebarProperties } from "../layers/layers";
import { useDispatch, useSelector } from "react-redux";
import {
    getActiveFeatureLayers,
    setCenterAndZoom,
    toggleActiveFeatureLayer,
} from "../redux/mapSlice";
import {
    CUSTOM_OVERLAY,
    WEATHER_ALERTS,
    WEATHER_RADAR,
} from "../layers/constants";
import SwitchBox from "./SwitchBox";
import searchIcon from "../images/search.png";
import { resetRadarAnimation } from "../redux/weatherRadarSlice";

// this component is for one of the controls which toggles a
// map feature layer on and off (flood gages, lake conditions, etc.)

const MapLayerControl = ({ layer }) => {
    const activeFeatureLayers = useSelector(getActiveFeatureLayers);
    const dispatch = useDispatch();

    const { icon, legend, text, altText } = sidebarProperties["" + layer];

    const isActive = !!(activeFeatureLayers & layer);

    const toggleFeatureLayer = (layer) => {
        if (layer === WEATHER_RADAR && isActive) {
            dispatch(resetRadarAnimation());
        }
        dispatch(toggleActiveFeatureLayer(layer));
    };

    const zoomToLayer = () => {
        const coordinates = JSON.parse(
            localStorage.getItem("flyToCoordinates")
        );
        dispatch(
            setCenterAndZoom({ lat: coordinates[0], lng: coordinates[1] })
        );
    };

    return (
        <>
            <div className="feature-layer-control">
                <img src={icon} alt={altText} />
                <span>{text}</span>
                {layer === CUSTOM_OVERLAY && (
                    <button
                        className="custom-overlay-zoom"
                        onClick={zoomToLayer}
                    >
                        <img
                            src={searchIcon}
                            style={{ width: "16px", height: "16px" }}
                            alt="Zoom to center"
                        />
                    </button>
                )}
                <SwitchBox
                    name={text}
                    onChange={() => {
                        toggleFeatureLayer(layer);
                    }}
                    checked={isActive}
                />
            </div>
            <div className="legend-area">
                {/* if the layer is active, show the legend image or 
      a link to it if it's the WEATHER_ALERTS link */}
                {isActive &&
                    (layer !== WEATHER_ALERTS ? (
                        <img src={legend} alt="legend" />
                    ) : (
                        <a href={legend} target="_blank">
                            NOAA Advisories
                        </a>
                    ))}
            </div>
        </>
    );
};

export default MapLayerControl;
