import { FaSave } from "react-icons/fa";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import {
    getCurrentAlerts,
    getGagesToUnsubscribe,
    getPredictiveAlerts,
} from "../redux/userSlice";
import { modalHidden } from "../redux/modalSlice";
import { subscriptionsChanged } from "../redux/userSliceThunks";

// This component confirms the user wants to unsubscribe from
// one or more of the currently subscribed gages

const SaveSubscriptionChanges = () => {
    const currentAlerts = useSelector(getCurrentAlerts);
    const predictiveAlerts = useSelector(getPredictiveAlerts);
    const gagesToUnsubscribe = useSelector(getGagesToUnsubscribe);
    const dispatch = useDispatch();

    const handleSaveChanges = (e) => {
        dispatch(modalHidden());

        let lidsToUnsubscribe = [];
        if (currentAlerts) {
            lidsToUnsubscribe = gagesToUnsubscribe.slice();
        }
        if (predictiveAlerts) {
            lidsToUnsubscribe = [
                ...lidsToUnsubscribe,
                ...gagesToUnsubscribe.map((gage) => gage + "--PD"),
            ];
        }
        dispatch(
            subscriptionsChanged({ lidsToSubscribe: [], lidsToUnsubscribe })
        );
    };

    return (
        <Modal>
            <p className="title">
                <FaSave className="icon" />
                Save Changes
            </p>
            <p className="body">Are you sure you want to save changes?</p>
            <div className="buttons">
                <button
                    onClick={() => {
                        dispatch(modalHidden());
                    }}
                >
                    CANCEL
                </button>
                <button onClick={handleSaveChanges}>CONFIRM</button>
            </div>
        </Modal>
    );
};

export default SaveSubscriptionChanges;
