import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showGeocoder } from "./redux/geocoderSlice";
import { changeBaseLayer, getCurrentBaseLayer } from "./redux/mapSlice";
import { setCustomGeoJSONLayer } from "./redux/geoJsonSlice";
import { SATELLITE, STREETS } from "./layers/constants";

import L from "leaflet";
import { FaBars, FaImage, FaSearch, FaUpload } from "react-icons/fa";
import logo from "./images/texas_flood_logo_transparent_300x42.png";
import icon from "./images/favicon.ico";
import centerOfMass from "@turf/center-of-mass";
import { toggleSidebar } from "./redux/sidebarSlice";
import MenuOverlay from "./MenuOverlay";

// this component is for the header bar at the top of the page

const Header = () => {
    const baseLayer = useSelector(getCurrentBaseLayer);
    const dispatch = useDispatch();

    const [showMenu, setShowMenu] = useState(false);

    const [centerImg, setCenterImg] = useState(
        window.innerWidth < 350 ? icon : logo
    );

    const toggleCenterImg = () => {
        setCenterImg(window.innerWidth < 350 ? icon : logo);
    };

    useEffect(() => {
        const resizeEvent = window.addEventListener("resize", toggleCenterImg);

        return () => {
            window.removeEventListener("resize", toggleCenterImg);
        };
    }, []);

    const setBaseLayer = (baseLayer) => {
        dispatch(changeBaseLayer(baseLayer));
        setShowMenu(false);
    };

    const fileSelected = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => {
                try {
                    // use Leaflet to test for valid geoJSON
                    const customJson = JSON.parse(reader.result);
                    let center = centerOfMass(customJson);
                    localStorage.setItem(
                        "flyToCoordinates",
                        JSON.stringify(center.geometry.coordinates.reverse()) // the centerOfMass function returns coordinates as lng/lat, so reverse them
                    );
                    L.geoJSON(customJson);
                    dispatch(setCustomGeoJSONLayer(customJson));
                } catch (e) {
                    alert("The input is not valid geoJSON");
                    dispatch(setCustomGeoJSONLayer(null));
                }
            };
        } else {
            dispatch(setCustomGeoJSONLayer(null));
        }
    };

    return (
        <div className="header">
            <button
                className="hamburger"
                onClick={() => {
                    dispatch(toggleSidebar());
                }}
                aria-label="toggle sidebar"
            >
                <FaBars />
            </button>
            <a
                className="header-logo"
                href="https://www.texasflood.org"
                target="_blank"
            >
                <img src={centerImg} alt="The Texas Flood dot org logo" />
            </a>
            <div className="header-controls">
                <div>
                    <label aria-label="upload custom geoJson">
                        <input
                            type="file"
                            name="file"
                            onChange={fileSelected}
                        />
                        <FaUpload />
                    </label>
                </div>
                <div>
                    <button
                        onClick={() => setShowMenu(true)}
                        aria-label="show basemap layers"
                    >
                        <FaImage />
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => {
                            dispatch(showGeocoder());
                        }}
                        aria-label="show geocoder"
                    >
                        <FaSearch />
                    </button>
                </div>
            </div>
            {showMenu && (
                <>
                    <MenuOverlay clickHandler={() => setShowMenu(false)} />
                    <div className="base-map-menu">
                        <div
                            style={{
                                fontWeight:
                                    baseLayer === STREETS ? "bold" : "normal",
                            }}
                            onClick={() => {
                                setBaseLayer(STREETS);
                            }}
                        >
                            {STREETS}
                        </div>
                        <div
                            style={{
                                fontWeight:
                                    baseLayer === SATELLITE ? "bold" : "normal",
                            }}
                            onClick={() => {
                                setBaseLayer(SATELLITE);
                            }}
                        >
                            {SATELLITE}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Header;
