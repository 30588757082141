import { useState } from "react";
import { useDispatch } from "react-redux";
import FormInput from "./FormInput";
import { addToast } from "../redux/toasterSlice";
import { confirmNewPassword, resendCode } from "../redux/userSliceThunks";

// this component is for the form which allows the user
// to verify the code sent to him or her and update the
// password

const ResetPasswordForm = () => {
    const dispatch = useDispatch();

    const [confirmationCode, setConfirmationCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!password) {
            dispatch(addToast({ msg: "Please enter a password" }));
            return;
        }
        if (password !== confirmPassword) {
            dispatch(addToast({ msg: "Passwords do not match" }));
            return;
        }

        dispatch(
            confirmNewPassword({
                verificationCode: confirmationCode,
                newPassword: password,
            })
        );
    };

    return (
        <div>
            <p>
                A verification code has been sent either to the email associated
                with this account or via text message to the phone number
                associated with this account.
            </p>
            <p>
                Please enter your 6 digit verification code and new password
                below.
            </p>
            <form onSubmit={handleSubmit}>
                <FormInput
                    label="Verification Code"
                    pattern="[0-9]*"
                    minLength={6}
                    maxLength={6}
                    error="6 digits required"
                    type="tel"
                    placeholder="Verification Code"
                    changeHandler={(e) => setConfirmationCode(e.target.value)}
                    value={confirmationCode}
                />
                <FormInput
                    label="New Password"
                    pattern=".{6,}"
                    minLength={6}
                    error="Minimum 6 characters."
                    type="password"
                    placeholder="New Password"
                    changeHandler={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <FormInput
                    label="Confirm New Password"
                    pattern=".{6,}"
                    minLength={6}
                    error="Minimum 6 characters."
                    type="password"
                    placeholder="Confirm New Password"
                    changeHandler={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                />
                <span className="submit-buttons">
                    <button type="submit">SUBMIT</button>
                </span>
            </form>
        </div>
    );
};

export default ResetPasswordForm;
