import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import L from "leaflet";
import "leaflet/dist/leaflet.css";

import { leafletBaseLayers, featureLayers } from "./layers/layers";
import {
    getActiveFeatureLayers,
    getCurrentBaseLayer,
    getCurrentCoords,
    setMapReady,
} from "./redux/mapSlice";
import useGeolocation from "./hooks/useGeolocation";
import useGeocoding from "./hooks/useGeocoding";
import useWeatherRadar from "./hooks/useWeatherRadar";
import useZoomToGage from "./hooks/useZoomToGage";
import Popup from "./popups/Popup";
import { FLOOD_GAGES } from "./layers/constants";
import store from "./redux/store";
import { useNavigate } from "react-router-dom";

// this component wraps the Leaflet map

const Map = () => {
    // State for this component
    // baseLayer: STREETS | SATELLITE
    // activeFeatureLayers: set of bit map flags in single integer
    const currentCoords = useSelector(getCurrentCoords);
    const baseLayer = useSelector(getCurrentBaseLayer);
    const activeFeatureLayers = useSelector(getActiveFeatureLayers);

    const dispatch = useDispatch();

    // ref which contains the leaflet map object
    const leafletMap = useRef(null);
    // the DOM element which will contain the leaflet map
    const mapElement = useRef();

    const navigate = useNavigate();

    const setBaseLayer = (leafletMap, layerName) => {
        // add the active base layer
        if (!leafletMap) return;

        const newBaseLayer = leafletBaseLayers[layerName];
        newBaseLayer.addTo(leafletMap);
        // remove any others
        Object.keys(leafletBaseLayers)
            .filter((key) => key !== layerName)
            .forEach((key) => {
                leafletMap.removeLayer(leafletBaseLayers[key]);
            });
    };

    const updateUrl = (isPopupShowing, popupData) => {
        if (isPopupShowing && popupData.id === FLOOD_GAGES) {
            navigate(`/gage/${popupData.lid}`);
        } else {
            const center = leafletMap.current.getCenter();
            const lat = center.lat.toPrecision(7);
            const lng = center.lng.toPrecision(7);
            const zoom = leafletMap.current.getZoom();
            navigate(`/map/@${lat},${lng},${zoom}z`);
        }
    };

    // initialize map the feature layers
    useEffect(() => {
        // useEffect gets run twice in dev due to strict mode
        // but map can only be initialized once, so adding check
        // to test if it already exists
        console.log("initializing Leaflet map");
        if (!leafletMap.current) {
            const m = L.map(mapElement.current, {
                center: [currentCoords.lat, currentCoords.lng],
                zoom: currentCoords.zoom,
                minZoom: window.innerWidth < 768 ? 5 : 6,
            });
            m.zoomControl.setPosition("bottomright");
            m.on("click", (e) => {
                L.DomEvent.preventDefault(e);
                L.DomEvent.stopPropagation(e);
            })
                .on("zoomend dragend", (e) => {
                    const mapState = store.getState().map;
                    updateUrl(mapState.isPopupShowing, mapState.popupData);
                })
                .on("popupopen", () => {
                    const mapState = store.getState().map;
                    updateUrl(mapState.isPopupShowing, mapState.popupData);
                })
                .on("popupclose", () => {
                    updateUrl(false);
                });
            leafletMap.current = m;
            dispatch(setMapReady(true));

            // iterate through the feature layers and initialize them
            // the layer classes themselves have already been constructed
            Object.keys(featureLayers).forEach((layerMaskStr) => {
                featureLayers[layerMaskStr].initialize(leafletMap.current);
            });
        }
    }, []);

    // set the base layer to the current one in the Redux store
    useEffect(() => {
        setBaseLayer(leafletMap.current, baseLayer);
    }, [baseLayer]);

    // set up the previous state of the feature layers bit field
    const activeFeatureLayersRef = useRef(0);

    // show/hide the feature layers as appropriate based on the Redux store
    useEffect(() => {
        // iterate through the layer objects
        Object.keys(featureLayers).forEach((layerMaskStr) => {
            // toggle any feature layers which may have changed since the last render
            const layerMask = parseInt(layerMaskStr);
            // if it's changed
            if (
                (activeFeatureLayers & layerMask) !==
                (activeFeatureLayersRef.current & layerMask)
            ) {
                if (activeFeatureLayers & layerMask) {
                    featureLayers[layerMaskStr].show(leafletMap.current);
                } else {
                    featureLayers[layerMaskStr].hide(leafletMap.current);
                }
            }
        });

        // update the ref
        activeFeatureLayersRef.current = activeFeatureLayers;
    }, [activeFeatureLayers]);

    // zoom to current coordinates
    useEffect(() => {
        if (leafletMap.current) {
            console.log(leafletMap.current.getCenter());
            leafletMap.current.setView(
                new L.LatLng(currentCoords.lat, currentCoords.lng),
                currentCoords.zoom
            );
        }
    }, [currentCoords]);

    // code specific to the Radar feature layer if it's on
    useWeatherRadar(leafletMap.current, featureLayers);

    useGeocoding(leafletMap.current);

    useGeolocation(leafletMap.current);

    useZoomToGage();

    return (
        <>
            <div className="map" ref={mapElement}></div>
            <Popup leafletMap={leafletMap.current} />
        </>
    );
};

export default Map;
