import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import keys from "../keys";
import { toggleActiveFeatureLayer } from "./mapSlice";

// this Redux slice is concerned with the state associated
// with the Weather Radar layer including the list of timestamps
// associated with a given radar image as well as which is
// currently displayed

export const fetchRadarTimestamps = createAsyncThunk(
    "weatherRadar/fetchRadarTimestamps",
    async () => {
        const response = await fetch(
            `https://maps.aerisapi.com/${keys.aerisApiId}_${keys.aerisApiSecret}/radar.json`
        );
        const result = await response.json();
        const timestamps = [
            result.files[20].time,
            result.files[15].time,
            result.files[10].time,
            result.files[5].time,
            result.files[0].time,
        ];
        return timestamps;
    }
);

// radarTimestamps is a list of timestamps used as
// part of the URL to the appropriate png file
// they are arranged oldest to newest in the array
const initialState = {
    currentRadarIndex: -1,
    isRadarAnimating: false,
    status: "idle",
    error: null,
    radarTimestamps: [],
};

export const getWeatherRadarStatus = (state) => state.weatherRadar.status;
export const getRadarAnimating = (state) => state.weatherRadar.isRadarAnimating;
export const getCurrentRadarIndex = (state) =>
    state.weatherRadar.currentRadarIndex;
export const getRadarTimestamps = (state) => state.weatherRadar.radarTimestamps;

const weatherRadarSlice = createSlice({
    name: "weatherRadar",
    initialState,
    reducers: {
        toggleRadarAnimation: (state, action) => {
            state.isRadarAnimating = !state.isRadarAnimating;
        },
        setCurrentRadarIndex: (state, action) => {
            state.currentRadarIndex = action.payload;
        },
        resetRadarAnimation: (state, action) => {
            state.isRadarAnimating = false;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchRadarTimestamps.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchRadarTimestamps.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.radarTimestamps = action.payload;
            })
            .addCase(fetchRadarTimestamps.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export const {
    toggleRadarAnimation,
    setCurrentRadarIndex,
    resetRadarAnimation,
} = weatherRadarSlice.actions;

export default weatherRadarSlice.reducer;
