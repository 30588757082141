import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import L from "leaflet";
import { getPopupData, getPopupShowing, hidePopup } from "../redux/mapSlice";
import {
    CUSTOM_OVERLAY,
    FLOOD_GAGES,
    LAKE_CONDITIONS,
    STATE_PARKS,
    WEATHER_ALERTS,
} from "../layers/constants";
import FloodGagePopup from "./FloodGagePopup";
import WeatherAlertsPopup from "./WeatherAlertsPopup";
import LakeConditionsPopup from "./LakeConditionsPopup";
import StateParkPopup from "./StateParkPopup";
import CustomOverlayPopup from "./CustomOverlayPopup";
import "./popup.css";

// The component controls which popup component appears and
// adds it to the map using the createPortal method from React DOM

const Popup = ({ leafletMap }) => {
    const leafletPopupRef = useRef(null);
    const popupData = useSelector(getPopupData);
    const isPopupShowing = useSelector(getPopupShowing);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!leafletPopupRef.current && popupData) {
            leafletPopupRef.current = L.popup({
                className: "popup",
                closeButton: false,
                maxHeight: 600,
                keepInView: true,
            });
            leafletPopupRef.current.setLatLng(
                L.latLng(popupData.lat, popupData.lng)
            );
            if (window.innerWidth > 800) {
                leafletPopupRef.current.options.minWidth =
                    popupData.id === LAKE_CONDITIONS ? 800 : 600;
            } else if (window.innerWidth > 600) {
                leafletPopupRef.current.options.minWidth =
                    popupData.id === LAKE_CONDITIONS
                        ? 0.9 * window.innerWidth
                        : 600;
            } else {
                leafletPopupRef.current.options.minWidth =
                    0.9 * window.innerWidth;
            }
            leafletPopupRef.current.openOn(leafletMap);
        }

        return () => {
            leafletPopupRef.current = null;
        };
    }, [popupData]);

    if (!leafletMap || !isPopupShowing || !leafletPopupRef.current) return null;

    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        leafletMap.closePopup();
        dispatch(hidePopup());
    };

    let body;
    switch (popupData.id) {
        case FLOOD_GAGES:
            body = (
                <FloodGagePopup
                    handleLoaded={() => {
                        leafletPopupRef.current.update();
                    }}
                    handleClose={handleClose}
                />
            );
            break;
        case LAKE_CONDITIONS:
            body = (
                <LakeConditionsPopup
                    handleLoaded={() => {
                        leafletPopupRef.current.update();
                    }}
                    handleClose={handleClose}
                />
            );
            break;
        case WEATHER_ALERTS:
            body = <WeatherAlertsPopup handleClose={handleClose} />;
            break;
        case STATE_PARKS:
            body = <StateParkPopup handleClose={handleClose} />;
            break;
        case CUSTOM_OVERLAY:
            body = <CustomOverlayPopup handleClose={handleClose} />;
            break;
        default:
            console.log("ERROR: unknown type of popup body");
    }

    return createPortal(body, leafletPopupRef.current._contentNode);
};

export default Popup;
