import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FormInput from "./FormInput";
import { resendCode, verifyCode } from "../redux/userSliceThunks";

// this component is for verifying a confirmation code
// sent to the user after signup to confirm that the
// phone number or address is valid

const VerifyConfirmationCode = () => {
    const dispatch = useDispatch();

    const [confirmationCode, setConfirmationCode] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(verifyCode(confirmationCode));
    };

    const handleResendCode = (e) => {
        e.preventDefault();
        dispatch(resendCode());
    };

    return (
        <div className="verify-confirmation-code">
            <p>
                A verification code has been sent to your phone or your email.
            </p>

            <p>
                Please enter your 6 digit verification code below to confirm
                your account.
            </p>
            <form onSubmit={onSubmit}>
                <FormInput
                    label="Verification Code"
                    minLength={6}
                    maxLength={6}
                    error="6 digits required"
                    pattern="[0-9]*"
                    type="tel"
                    value={confirmationCode}
                    changeHandler={(e) => {
                        setConfirmationCode(e.target.value);
                    }}
                    placeholder="Verification Code"
                />
                <span className="submit-buttons">
                    <button type="submit">SUBMIT</button>
                </span>
            </form>
            <div className="login-other-options">
                <p>
                    Having trouble?{" "}
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            handleResendCode(e);
                        }}
                    >
                        Resend Verification Code
                    </a>
                </p>
            </div>
        </div>
    );
};

export default VerifyConfirmationCode;
