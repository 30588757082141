//Save a copy of this file in the current location as "secrets.es"
// and enter key values
export default {
  bingApiKey: "AhYqoB8FDpcDqJRTOu199sagiX1Rd0_LL4Hh9EHnhczK1vbgFEQ6O2VRMpxIwIMd",
  googleAnalyticsId: "UA-491601-14",
  aerisApiId: "eMAloma1gQZpqQdL1sURH",
  aerisApiSecret: "t1ldo2GReZxPxaB86Pez3SRRFHZLSLxPJfFjZvTx",
  awsConfig: {
  	region: "us-east-1",
    UserPoolId: 'us-east-1_3LyfiOdWZ',
    ClientId: '602n2i4g3loov3mnml95gdpoad',
    IdentityPoolId: 'us-east-1:cc613ffe-dbc7-474c-8661-2ed6854b243c',
    Logins: {
      cognito: {
        identityProviderName: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_3LyfiOdWZ'
      }
    }
  },
  SNS_TOPIC_ARN_BASE: "arn:aws:sns:us-east-1:746466009731:"
}
