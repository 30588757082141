import { useDispatch, useSelector } from "react-redux";
import { getPopupData, hideConfirmSubscribed } from "../redux/mapSlice";
import { getCurrentAlerts, getPredictiveAlerts } from "../redux/userSlice";
import Modal from "./Modal";
import { addToast } from "../redux/toasterSlice";
import AlertTypeIndicator from "../sidebar/AlertTypeIndicator";
import { subscriptionsChanged } from "../redux/userSliceThunks";
import { FaRegCheckSquare } from "react-icons/fa";
import { modalHidden } from "../redux/modalSlice";

// This component shows the modal that allows the user to confirm
// a subscription to the particular gage shown in the flood gage
// popup

const ConfirmSubscription = () => {
    const popupData = useSelector(getPopupData);
    const current = useSelector(getCurrentAlerts);
    const predictive = useSelector(getPredictiveAlerts);

    const dispatch = useDispatch();

    const handleSubscriptionConfirmed = () => {
        dispatch(modalHidden());

        const lidsToSubscribe = [];
        if (current) {
            lidsToSubscribe.push(popupData.lid);
        }
        if (predictive) {
            lidsToSubscribe.push(popupData.lid + "--PD");
        }
        if (current || predictive) {
            dispatch(
                subscriptionsChanged({
                    lidsToSubscribe,
                    lidsToUnsubscribe: [],
                    subscribingToGage: popupData.lid.toUpperCase(),
                })
            );
        } else {
            dispatch(
                addToast({
                    msg: "You have no alert types enabled in your Settings. You must have at least one alert type enabled to subscribe to gages.",
                })
            );
        }
    };

    return (
        <Modal>
            <div className="title">
                <FaRegCheckSquare className="icon" />
                Confirm Subscription
                <span className="alert-type-container">
                    <AlertTypeIndicator />
                </span>
            </div>
            <div>
                <p className="body">
                    Are you sure you want to subscribe to receive alerts for the{" "}
                    {popupData.name} ({popupData.lid}) flood gage?
                </p>
                <div className="buttons">
                    <button
                        onClick={() => {
                            dispatch(modalHidden());
                        }}
                    >
                        CANCEL
                    </button>
                    <button onClick={handleSubscriptionConfirmed}>
                        CONFIRM
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmSubscription;
