import { useSelector, useDispatch } from "react-redux";
import {
    gageToUnsubscribeAdded,
    gageToUnsubscribeRemoved,
    getGagesToUnsubscribe,
} from "../redux/userSlice";
import { getFloodGages } from "../redux/floodSlice";
import { SAVE_SUBSCRIPTION_CHANGES, modalShown } from "../redux/modalSlice";
import useZoomToGage from "../hooks/useZoomToGage";
import AlertTypeIndicator from "./AlertTypeIndicator";
import SwitchBox from "./SwitchBox";
import { FaMapMarkerAlt } from "react-icons/fa";

// This component shows the list of current subscriptions for this user

const SubscriptionForm = ({ lids }) => {
    const floodGages = useSelector(getFloodGages);
    const gagesToUnsubscribe = useSelector(getGagesToUnsubscribe);
    const dispatch = useDispatch();
    const zoomToGage = useZoomToGage();

    lids = lids.slice().sort();

    let subscriptionsList = "";
    if (floodGages) {
        subscriptionsList = lids.map((lid) => {
            const sigStage = floodGages[lid].sigstage;
            const kebabSigStage = "zoom-" + sigStage.replaceAll(" ", "-");
            return (
                <li key={lid}>
                    <button
                        aria-label="zoomToGage"
                        className={kebabSigStage}
                        onClick={(e) => {
                            e.preventDefault();
                            zoomToGage(lid);
                        }}
                    >
                        <FaMapMarkerAlt />
                    </button>

                    <div>
                        <span className="gage-acronym">{lid}</span>{" "}
                        <span className="full-gage-name">
                            {floodGages[lid].name}
                        </span>
                    </div>
                    <div
                        className="switch-container"
                        title="Changes will not be made until you click SAVE CHANGES"
                    >
                        <small>Alert</small>
                        <SwitchBox
                            name={lid}
                            onChange={() => {
                                toggleGageSubscription(lid);
                            }}
                            checked={!gagesToUnsubscribe.includes(lid)}
                        />
                    </div>
                </li>
            );
        });
    }

    const toggleGageSubscription = (lid) => {
        if (gagesToUnsubscribe.includes(lid)) {
            dispatch(gageToUnsubscribeRemoved(lid));
        } else {
            dispatch(gageToUnsubscribeAdded(lid));
        }
    };

    const handleShowModal = (e) => {
        e.preventDefault();
        dispatch(modalShown(SAVE_SUBSCRIPTION_CHANGES));
    };

    return (
        <div>
            <p>
                Total Subcriptions{" "}
                <span className="badge subscription-count">{lids.length}</span>
            </p>
            <p>Click the marker next to a gage to zoom to its location</p>
            <p>
                To unsbscribe from a gage, uncheck it in the list and save your
                changes.
            </p>
            <AlertTypeIndicator />
            <form onSubmit={handleShowModal}>
                <ul className="subscription-list">{subscriptionsList}</ul>
                <button type="submit" disabled={gagesToUnsubscribe.length <= 0}>
                    SAVE CHANGES
                </button>
            </form>
        </div>
    );
};

export default SubscriptionForm;
