import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeToast, updateToast } from "./redux/toasterSlice";

const Toast = ({ toast }) => {
    const dispatch = useDispatch();
    const { id, msg, duration, status } = toast;

    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(updateToast({ ...toast, status: "end" }));
        }, duration);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    let className = "toast";
    if (status === "begin") {
        className += " genie";
    } else if (status === "end") {
        className += " genie-out";
    }

    const handleAnimationEnded = () => {
        if (status === "begin") {
            dispatch(updateToast({ ...toast, status: "showing" }));
        } else if (status === "end") {
            dispatch(removeToast(id));
        }
    };

    return (
        <div
            className={className}
            style={{
                marginTop: status === "showing" && "10px",
            }}
            onAnimationEnd={handleAnimationEnded}
        >
            {msg}
        </div>
    );
};

export default Toast;
