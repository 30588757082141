import {
    fetchRadarTimestamps,
    setCurrentRadarIndex,
    toggleRadarAnimation,
} from "../redux/weatherRadarSlice";
import L from "leaflet";

// this module contains the class for the Weather Radar tile layer
// it is implemented as a series of png files for 6 timestamps in
// the past.  When animating, you iterate through the timestamps
// making each visible and the others invisible in turn by setting
// their opacity.  Works in conjunction with the RadarAnimation
// component, the useWeatherRadar hook and the weatherRadarSlice
// to manage redux state

export default class WeatherRadarLayer {
    constructor(id, baseUrl, zIndex, refreshTimeMs, reduxStore) {
        this.id = id;
        this.baseUrl = baseUrl;
        this.zIndex = zIndex;
        this.refreshTimeMs = refreshTimeMs;
        this.reduxStore = reduxStore;
        this.tileLayers = [];
    }

    initialize() {
        // no-op
    }

    update() {
        this.reduxStore.dispatch(fetchRadarTimestamps());
    }

    // called by Map component when the timestamps are ready
    // this creates the tileLayer objects which are always present,
    // but have their opacity turned off
    // we can't set this up initially, because the URL changes every
    // time the timestamps are refreshed
    setupLayers(map) {
        // if there are any, remove any layers already added to the map
        this.tileLayers.forEach((layer) => {
            if (map.hasLayer(layer)) {
                map.removeLayer(layer);
            }
        });
        const storeState = this.reduxStore.getState();
        const timestamps = storeState.weatherRadar.radarTimestamps;
        this.tileLayers = timestamps.map((timeStamp) => {
            return L.tileLayer(`${this.baseUrl}${timeStamp}.png`, {
                attribution:
                    '<a href="http://www.aerisweather.com">AerisWeather</a>',
                zIndex: this.zIndex,
                subdomains: "1234",
                opacity: 0,
            });
        });
        this.tileLayers.forEach((layer) => {
            map.addLayer(layer);
        });
        this.reduxStore.dispatch(setCurrentRadarIndex(timestamps.length - 1));
        this.tileLayers[timestamps.length - 1].setOpacity(0.8);
    }

    show() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        if (this.animationInterval) {
            clearInterval(this.animationInterval);
        }
        this.update();
        this.refreshInterval = setInterval(() => {
            this.update();
        }, this.refreshTimeMs);
    }

    hide(map) {
        if (this.animationInterval) {
            clearInterval(this.animationInterval);
            this.reduxStore.dispatch(
                setCurrentRadarIndex(this.tileLayers.length - 1)
            );
            const storeState = this.reduxStore.getState();
            if (storeState.weatherRadar.isRadarAnimating) {
                this.reduxStore.dispatch(toggleRadarAnimation());
            }
        }
        this.tileLayers.forEach((layer) => {
            if (map.hasLayer(layer)) {
                map.removeLayer(layer);
            }
        });
        clearInterval(this.refreshInterval);
    }

    animate() {
        const storeState = this.reduxStore.getState();

        if (!storeState.weatherRadar.isRadarAnimating) return;

        if (this.animationInterval) {
            clearInterval(this.animationInterval);
        }
        const intervalPeriod = 1000;
        this.animationInterval = setInterval(() => {
            const storeState = this.reduxStore.getState();
            const currentIndex = storeState.weatherRadar.currentRadarIndex;
            this.tileLayers[currentIndex].setOpacity(0);
            const nextIndex =
                currentIndex < this.tileLayers.length - 1
                    ? currentIndex + 1
                    : 0;
            this.tileLayers[nextIndex].setOpacity(0.8);
            this.reduxStore.dispatch(setCurrentRadarIndex(nextIndex));
        }, intervalPeriod);
    }

    stopAnimation() {
        if (this.animationInterval) {
            clearInterval(this.animationInterval);
        }
        const storeState = this.reduxStore.getState();
        const currentIndex = storeState.weatherRadar.currentRadarIndex;
        // if not already on the latest radar image (i.e. the last timestamp in the array), switch to it
        if (currentIndex != this.tileLayers.length - 1) {
            this.tileLayers[currentIndex].setOpacity(0);
            const nextIndex = this.tileLayers.length - 1;
            this.tileLayers[nextIndex].setOpacity(0.8);
            this.reduxStore.dispatch(setCurrentRadarIndex(nextIndex));
        }
    }
}
