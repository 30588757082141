import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showSidebar: false,
};

export const getSidebarShowing = (state) => state.sidebar.showSidebar;

const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        sidebarShown: (state, action) => {
            state.showSidebar = true;
        },
        toggleSidebar: (state, action) => {
            state.showSidebar = !state.showSidebar;
        },
        sidebarHidden: (state, action) => {
            state.showSidebar = false;
        },
    },
});

export const { sidebarShown, toggleSidebar, sidebarHidden } =
    sidebarSlice.actions;

export default sidebarSlice.reducer;
