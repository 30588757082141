import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGeocoderResults, hideGeocoder } from "../redux/geocoderSlice";

// this hook encapsulates the code
// which causes the map to navigate
// to navigate to a selected geocode

const useGeocoding = (leafletMap) => {
    const geocodingResults = useSelector(getGeocoderResults);
    const dispatch = useDispatch();

    useEffect(() => {
        if (leafletMap && geocodingResults.length === 1) {
            // pan to selected geocode location
            const boundingBoxCoords = geocodingResults[0].boundingbox;
            leafletMap.fitBounds([
                [boundingBoxCoords[0], boundingBoxCoords[2]],
                [boundingBoxCoords[1], boundingBoxCoords[3]],
            ]);
            dispatch(hideGeocoder());
        }
    }, [geocodingResults.length]);
};

export default useGeocoding;
