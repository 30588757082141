import { useState } from "react";
import { useDispatch } from "react-redux";
import { LOGIN_FORM, currentFormChanged } from "../redux/userSlice";
import FormInput from "./FormInput";
import { forgotPassword } from "../redux/userSliceThunks";
import { FaArrowAltCircleLeft } from "react-icons/fa";

// this module is for the form displayed when the user
// indicates he/she has forgotten his/her password

const ForgotPasswordForm = () => {
    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");

    const handleChangePassword = (e) => {
        e.preventDefault();

        dispatch(forgotPassword(username ? username : "+1" + phone));
    };

    return (
        <div>
            <p>Enter your username or phone number to change your password.</p>
            <form onSubmit={handleChangePassword}>
                <FormInput
                    label="Username"
                    style={{ display: phone === "" ? "inline" : "none" }}
                    type="text"
                    placeholder="Username"
                    changeHandler={(e) => setUsername(e.target.value)}
                />
                <p
                    style={{
                        display:
                            username === "" && phone === "" ? "inline" : "none",
                    }}
                >
                    or
                </p>
                <FormInput
                    label="Phone Number"
                    style={{ display: username === "" ? "inline" : "none" }}
                    type="tel"
                    placeholder="Phone Number"
                    changeHandler={(e) => setPhone(e.target.value)}
                    pattern="[0-9]*"
                    minLength={10}
                    maxLength={10}
                    error="10 digits only including US area code"
                />
                <span className="submit-buttons">
                    <button type="submit">SUBMIT</button>
                </span>
            </form>
            <button
                type="button"
                onClick={() => {
                    dispatch(currentFormChanged(LOGIN_FORM));
                }}
            >
                <FaArrowAltCircleLeft aria-label="go back" />
            </button>
        </div>
    );
};

export default ForgotPasswordForm;
