import { useState } from "react";
import Modal from "./Modal";
import SwitchBox from "../sidebar/SwitchBox";
import { FaSave } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
    getCurrentAlerts,
    getLids,
    getNewCurrentAlerts,
    getNewPredictiveAlerts,
    getPredictiveAlerts,
} from "../redux/userSlice";
import { alertsChanged, subscriptionsChanged } from "../redux/userSliceThunks";
import { modalHidden } from "../redux/modalSlice";

// This component is for the modal dialog that appears when
// the user turns on or off current or predictive alerts

const SaveAlertChanges = () => {
    const lids = useSelector(getLids);
    const current = useSelector(getCurrentAlerts);
    const predictive = useSelector(getPredictiveAlerts);
    const newCurrent = useSelector(getNewCurrentAlerts);
    const newPredictive = useSelector(getNewPredictiveAlerts);
    const dispatch = useDispatch();

    const [acceptConsequences, setAcceptConsequences] = useState(false);

    const handleSaveAlerts = () => {
        dispatch(modalHidden());

        const predictiveLids = lids.map((lid) => lid + "--PD");

        let lidsToSubscribe = [];
        let lidsToUnsubscribe = [];

        if (!newCurrent && current) {
            lidsToUnsubscribe = [...lidsToSubscribe, ...lids];
        }
        if (!newPredictive && predictive) {
            lidsToUnsubscribe = [...lidsToUnsubscribe, ...predictiveLids];
        }
        if (newCurrent && !current) {
            lidsToSubscribe = [...lidsToSubscribe, ...lids];
        }
        if (newPredictive && !predictive) {
            lidsToSubscribe = [...lidsToSubscribe, ...predictiveLids];
        }
        dispatch(subscriptionsChanged({ lidsToSubscribe, lidsToUnsubscribe }));

        dispatch(
            alertsChanged({ current: newCurrent, predictive: newPredictive })
        );
    };

    return (
        <Modal>
            <p className="title">
                <FaSave className="icon" />
                Save Changes
            </p>
            <p className="body">
                Are you sure you want to change your alert types?
            </p>
            <p className="body">
                This will alter the SMS messages you receive.
            </p>
            <p className="body">
                <strong>
                    Disabling both will cause you to lose all of your
                    subscriptions.
                </strong>
            </p>
            <div className="body">
                <SwitchBox
                    name="acceptConsequences"
                    onChange={() => {
                        setAcceptConsequences((prev) => !prev);
                    }}
                    checked={acceptConsequences}
                />
                <span>I understand</span>
            </div>
            <div className="buttons">
                <button
                    onClick={() => {
                        dispatch(modalHidden());
                    }}
                >
                    CANCEL
                </button>
                <button
                    onClick={handleSaveAlerts}
                    disabled={!acceptConsequences}
                >
                    CONFIRM
                </button>
            </div>
        </Modal>
    );
};

export default SaveAlertChanges;
