import { createAsyncThunk } from "@reduxjs/toolkit";
import { addToast } from "./toasterSlice";
import {
    authenticate,
    logout as signOut,
    signUp as register,
    updateAlerts,
    confirmCode,
    resendCode as retransmitCode,
    forgotPassword as unknownPassword,
    updatePassword,
    deleteUser,
    retrieveLoggedInUserAfterRefresh,
} from "../user/user";
import {
    getSubscriptions,
    subscribe as addSubscriptions,
    unsubscribe as removeSubscriptions,
    sendSubscriptionMessage,
} from "../user/subscriptions";
import { sendSNSError } from "../errorReporting";

// Normally, the thunks would go with their associated slice, the user slice,
// but that file was getting unwieldy, so I broke them out into a separate file

const getUserPayload = (idToken, subscriptionResults) => {
    const subscriptions = subscriptionResults.subscriptions.filter(
        (record) => record.Value
    );

    const username = idToken.payload["cognito:username"];
    const payload = {
        authenticated: true,
        username,
        email: idToken.payload.email,
        phone: idToken.payload.phone_number,
        currentAlerts: idToken.payload["custom:currentAlerts"] === "T",
        predictiveAlerts: idToken.payload["custom:predictiveAlerts"] === "T",
        lids: getCleanedLidsFromSubscriptions(subscriptions),
    };
    return payload;
};

const getCleanedLidsFromSubscriptions = (subscriptions) => {
    // Create a set of lids which only show the name of the gage,
    // but not the alert type by removing part of the string
    // that indicates the lid is predictive and adding them all
    // to a set to remove the duplicates
    const lidSet = new Set(
        subscriptions.map((sub) => {
            if (sub.Key.endsWith("--PD")) {
                return sub.Key.replace(/--PD/g, "");
            }
            return sub.Key;
        })
    );
    return [...lidSet];
};

export const login = createAsyncThunk(
    "user/login",
    async ({ username, password }, thunkAPI) => {
        try {
            const result = await authenticate(username, password);
            const subscriptionResults = await getSubscriptions();
            const payload = getUserPayload(result.idToken, subscriptionResults);
            thunkAPI.dispatch(addToast({ msg: `Hello ${username}!!` }));
            if (payload.lids === 0) {
                thunkAPI.dispatch(
                    addToast({
                        msg: "No subscriptions found. Click a gage to subscribe and start receiving notifications.",
                    })
                );
            }
            return payload;
        } catch (err) {
            if (err.name === "NotAuthorizedException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "Incorrect username or password. Please try again.",
                    })
                );
            } else if (err.name === "UserNotFoundException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "Username or Phone Number is not registered. Please try again.",
                    })
                );
            } else if (err.name === "UserNotConfirmedException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "User has not been confirmed. Please sign up again and confirm your account.",
                    })
                );
            } else if (err.name === "LimitExceededException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "Attempt limit exceeded, please try after some time.",
                    })
                );
            } else {
                // TODO dispatch error to our SNS topic  see original code StevieActions
                await sendSNSError(err);
                thunkAPI.dispatch(
                    addToast({
                        msg: "There was an error. The support team has been notified. Please try again.",
                    })
                );
            }
            return thunkAPI.rejectWithValue(err.name);
        }
    }
);

// change the current and/or predictive flag in
// in the user pool
export const alertsChanged = createAsyncThunk(
    "user/alertsChanged",
    async ({ current, predictive }) => {
        await updateAlerts(current, predictive);
        return { current, predictive };
    }
);

// We pass in both the list of lids to subscribe and unsubscribe
// to handle all the use cases from subscribing to an individual
// lid from the popup, to unsubscribing to a list of seleced lids
// in the subscriptions list, to subscribing/unsubscribing from
// all current or predictive lids at once in the account settings
export const subscriptionsChanged = createAsyncThunk(
    "user/subscriptionsChanged",
    async (
        { lidsToSubscribe, lidsToUnsubscribe, subscribingToGage },
        thunkAPI
    ) => {
        let results;
        // we have to call getSubscriptions before both subscribing
        // and unsubscribing because there is a new SyncSessionToken
        // for each ListRecords/UpdateRecords pair
        if (lidsToSubscribe.length > 0) {
            const phone = thunkAPI.getState().user.phone;
            const email = thunkAPI.getState().user.email;
            const endpoint = phone ? phone : email;
            const protocol = phone ? "sms" : "email";
            results = await getSubscriptions();
            await addSubscriptions(
                lidsToSubscribe,
                endpoint,
                protocol,
                results
            );
        }
        if (lidsToUnsubscribe.length > 0) {
            results = await getSubscriptions();
            await removeSubscriptions(lidsToUnsubscribe, results);
        }
        results = await getSubscriptions();

        const subscriptions = results.subscriptions.filter(
            (record) => record.Value
        );

        const payload = {
            lids: getCleanedLidsFromSubscriptions(subscriptions),
        };
        if (subscribingToGage) {
            await sendSubscriptionMessage(subscribingToGage);
            thunkAPI.dispatch(
                addToast({
                    msg: `You have subscribed to the ${subscribingToGage} flood gage.`,
                })
            );
        }
        return payload;
    }
);

export const logout = createAsyncThunk("user/logout", async (_, thunkAPI) => {
    try {
        await signOut();
        thunkAPI.dispatch(
            addToast({
                msg: "You have successfully signed out.",
            })
        );
    } catch (err) {
        // TODO dispatch error to our SNS topic  see original code StevieActions
        await sendSNSError(err);
        thunkAPI.dispatch(
            addToast({
                msg: "There was an error. The support team has been notified. Please try again.",
            })
        );
        return thunkAPI.rejectWithValue(err.name);
    }
});

export const signUp = createAsyncThunk(
    "user/signUp",
    async ({ username, password, email, phone }, thunkAPI) => {
        try {
            let result = await register(username, password, email, phone);
            const payload = {
                username: result.user.username,
                signUpPassword: password, // save password to authenticate after code verification
                email,
                phone,
                currentAlerts: true,
                predictiveAlerts: true,
                lids: [],
            };

            return payload;
        } catch (err) {
            if (err.name === "UsernameExistsException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "Username is already registered. Please try a different username.",
                    })
                );
            } else {
                // TODO dispatch error to our SNS topic  see original code StevieActions
                await sendSNSError(err);
                thunkAPI.dispatch(
                    addToast({
                        msg: "There was an error. The support team has been notified. Please try again.",
                    })
                );
            }
            return thunkAPI.rejectWithValue(err.name);
        }
    }
);

export const verifyCode = createAsyncThunk(
    "user/verifyCode",
    async (code, thunkAPI) => {
        try {
            const username = thunkAPI.getState().user.username;
            const signUpPassword = thunkAPI.getState().user.signUpPassword;
            await confirmCode(code);
            await authenticate(username, signUpPassword);
            thunkAPI.dispatch(
                addToast({
                    msg: `Hello ${username}!!`,
                })
            );
        } catch (err) {
            if (err.name === "CodeMismatchException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "Incorrect validation code. Please try again.",
                    })
                );
            } else if (err.name === "AliasExistsException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "An account with this Email or phone number already exists. Try 'Forgot Password'",
                    })
                );
                return { aliasExists: true };
            } else {
                // TODO dispatch error to our SNS topic  see original code StevieActions
                await sendSNSError(err);
                thunkAPI.dispatch(
                    addToast({
                        msg: "There was an error. The support team has been notified. Please try again.",
                    })
                );
            }
            return thunkAPI.rejectWithValue(err.name);
        }
    }
);

export const resendCode = createAsyncThunk(
    "user/resendCode",
    async (_, thunkAPI) => {
        try {
            await retransmitCode();
            thunkAPI.dispatch(
                addToast({
                    msg: "New verification code sent. The previous code is now invalid.",
                })
            );
        } catch (err) {
            // TODO dispatch error to our SNS topic  see original code StevieActions
            await sendSNSError(err);
            thunkAPI.dispatch(
                addToast({
                    msg: "There was an error. The support team has been notified. Please try again.",
                })
            );
            return thunkAPI.rejectWithValue(err.name);
        }
    }
);

export const forgotPassword = createAsyncThunk(
    "user/forgotPassword",
    async (username, thunkAPI) => {
        try {
            const result = await unknownPassword(username);
            thunkAPI.dispatch(
                addToast({
                    msg: `Code sent to: ${username}`,
                })
            );
        } catch (err) {
            if (err.name === "UserNotFoundException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "Username/Phone Number not found. Please check the spelling and try again.",
                    })
                );
            } else if (err.name === "LimitExceededException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "Attempt limit exceeded, please try after some time.",
                    })
                );
            } else {
                // TODO dispatch error to our SNS topic  see original code StevieActions
                await sendSNSError(err);
                thunkAPI.dispatch(
                    addToast({
                        msg: "There was an error. The support team has been notified. Please try again.",
                    })
                );
            }
            return thunkAPI.rejectWithValue(err.name);
        }
    }
);

export const confirmNewPassword = createAsyncThunk(
    "user/confirmNewPassword",
    async ({ verificationCode, newPassword }, thunkAPI) => {
        try {
            await updatePassword(verificationCode, newPassword);
            thunkAPI.dispatch(
                addToast({
                    msg: "Your password has been reset.",
                })
            );
        } catch (err) {
            if (err.name === "InvalidParameterException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "No verified email or phone number for this username. Please check the spelling or try using your phone number.",
                    })
                );
            } else if (err.name === "CodeMismatchException") {
                thunkAPI.dispatch(
                    addToast({
                        msg: "Incorrect validation code. Please try again.",
                    })
                );
            } else {
                // TODO dispatch error to our SNS topic  see original code StevieActions
                await sendSNSError(err);
                thunkAPI.dispatch(
                    addToast({
                        msg: "There was an error. The support team has been notified. Please try again.",
                    })
                );
            }
            return thunkAPI.rejectWithValue(err.name);
        }
    }
);

export const deleteAccount = createAsyncThunk(
    "user/deleteAccount",
    async () => {
        await deleteUser();
    }
);

export const retrieveAccount = createAsyncThunk(
    "user/retrieveAccount",
    async (_, thunkAPI) => {
        try {
            const idToken = await retrieveLoggedInUserAfterRefresh();
            if (!idToken) return null;
            const subscriptionResults = await getSubscriptions();
            const payload = getUserPayload(idToken, subscriptionResults);
            return payload;
        } catch (err) {
            return thunkAPI.fulfillWithValue(null);
        }
    }
);
