import { FaBell, FaTimes } from "react-icons/fa";
// this component is for the black header bar in the popup

const PopupTitle = ({ icon, title, handleClose, handleSubscribeClicked }) => {
    return (
        <div className="popup-title">
            <img src={icon} className="popup-icon" />
            <span className="popup-title-text">{title}</span>
            {title === "Flood Gage" && (
                <>
                    <button
                        type="button"
                        className="button subscribe-button"
                        onClick={handleSubscribeClicked}
                    >
                        <FaBell />
                        <span>SUBSCRIBE</span>
                    </button>
                </>
            )}
            <button
                type="button"
                className="button popup-close"
                onClick={handleClose}
            >
                <FaTimes aria-label="close" />
            </button>
        </div>
    );
};

export default PopupTitle;
